<template>
  <div v-if="props.viewType === DataViewType.Grid" class="folder frame" @click="openViewFolder(props.item)">
    <PlaylistImage :source="getThumbnail(0)" class="thumbnails" :border-radius="[6, 6, 0, 0]">
      <div class="info">
        <div>
          <div class="title">
            <Tooltip :placement="'top'" :content="item.name" :textLength="20" />
          </div>
          <div class="params">
            <div>{{ getDateString(item.create_date) }}</div>
          </div>
        </div>
        <ActionButtons :actions="actions" :view-type="DataViewType.Grid" />
      </div>
    </PlaylistImage>
    <div class="small-videos">
      <PlaylistImage :source="getThumbnail(1)" :blank="props.item.videos && props.item.videos.length < 3" class="thumbnails" :border-radius="[6, 6, 6, 6]" />
      <PlaylistImage :source="getThumbnail(2)" :blank="props.item.videos && props.item.videos.length < 4" class="thumbnails" :border-radius="[6, 6, 6, 6]" />
      <PlaylistImage :source="getThumbnail(3)" :blank="props.item.videos && props.item.videos.length < 5" class="thumbnails" :border-radius="[6, 6, 6, 6]" />
      <PlaylistImage :source="getThumbnail(4)" :blank="props.item.videos && props.item.videos.length < 6" class="thumbnails" :border-radius="[6, 6, 6, 6]">
        <div v-if="props.item.number_of_videos > 5" class="morevideos">+{{ props.item.number_of_videos - 5 }}</div>
      </PlaylistImage>
    </div>
  </div>

  <div
    v-if="props.viewType === DataViewType.List"
    ref="dataViewRow"
    class="folder list"
    :style="`grid-template-columns:  ${columnsGridTemplate(props.columns)};`"
    @click="openViewFolder(props.item)"
  >
    <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'name')">
      <div class="title">
        <Tooltip :placement="'top'" :content="props.item.name" :textLength="20" />
      </div>
    </div>
    <div class="head d-flex" :class="shouldHideOnBreakpoint(props.columns, 'number_of_videos')">
      <div class="title flex-one">
        {{ props.item.number_of_videos }}
      </div>
      <div class="small-list-videos flex-one">
        <PlaylistImage
          v-for="index in indicesToShow"
          :key="index"
          :source="getThumbnail(index)"
          :blank="props.item.videos && props.item.videos.length <= index"
          class="thumbnails"
          :border-radius="getBorderRadius(index, numberOfVideosToShow)"
        />
        <PlaylistImage :source="getThumbnail(4)" :blank="props.item.videos && props.item.videos.length < 5" class="thumbnails" :border-radius="[3, 0, 0, 3]">
          <div v-if="props.item.number_of_videos > 5" class="morevideos">+{{ props.item.number_of_videos - numberOfVideosToShow - 1 }}</div>
        </PlaylistImage>
      </div>
    </div>

    <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'create_date')">
      <div class="title">
        {{ getDateString(props.item.create_date) }}
      </div>
    </div>
    <ActionButtons :actions="actions" :class="shouldHideOnBreakpoint(props.columns, 'actions')" :is-visible="isRowHovered" />
  </div>
</template>

<script setup lang="ts">
import PlaylistImage from '@/components/content/playlist/PlaylistImage.vue'
import { getDateString, openModalCustomConfirmation } from '@/services/utils'
import { Tooltip } from '@/components/common'
import { useRouter } from 'vue-router'
import { CRUD, DataType, DataViewType, DataActions } from '@/interfaces'
import { isDefaultGeneralFolder } from '@/services/utils'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import { ref, computed, onMounted, onBeforeUnmount, inject } from 'vue'
import { useElementHover } from '@vueuse/core'
import addVideosIconPlaceholder from '@/assets/playlists/playlist/add-videos-icon-placeholder.svg'
import { ModalType } from '@/components/modals/modals-util'
import { apiService } from '@/services'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { VIDEO_ACTIONS_INJECTION_KEY } from '@/constants'

const store = useStore()
const { t } = useI18n()
const dataActions = inject<DataActions>(VIDEO_ACTIONS_INJECTION_KEY)
const image = new window.Image()
image.src = addVideosIconPlaceholder

const props = defineProps(['item', 'viewType', 'columns', 'detailsLink', 'pageRoute'])
const router = useRouter()
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const actions = [
  {
    label: 'edit',
    handler: openEditFolder,
    translationKey: 'edit',
    isDisabled: isDefaultGeneralFolder(props.item.name)
  },
  {
    label: 'delete',
    handler: openDeleteFolder,
    translationKey: 'delete',
    isDisabled: isDefaultGeneralFolder(props.item.name)
  }
]
const modalType = ModalType.FOLDERS_DELETE_FOLDER_MODAL

function openViewFolder(item) {
  router.push({
    name: 'view-folder',
    params: { folderId: item.id }
  })
}

function openEditFolder() {
  router.push({
    path: `content/folders/edit/${props.item.id}`,
    name: 'edit-folder',
    params: { id: props.item.id }
  })
}

function openDeleteFolder() {
  openModalCustomConfirmation({ action: CRUD.Delete, type: DataType.Folder, itemName: props.item.title, id: props.item.id, onConfirm: deleteFolder }, modalType)
}

const deleteFolder = async (params) => {
  return apiService.folder
    .deleteFolder(params.id as number)
    .then(() => {
      store.dispatch('messages/addMessage', {
        message: t('folder-delete-msg'),
        type: 'success'
      })
      if (dataActions?.refreshData) dataActions.refreshData()
    })
    .catch((err) => {
      store.dispatch('messages/addMessage', {
        message: err.response.data.error.description,
        type: 'fail'
      })
    })
}

function getBorderRadius(index, numberOfVideosToShow) {
  if (index === 0) return [0, 3, 3, 0]
  if (index === numberOfVideosToShow - 1) return [3, 0, 0, 3]
  return [3, 3, 3, 3]
}

function getThumbnail(i) {
  if (props && props.item && Array.isArray(props.item.videos) && props.item.videos.length > i) {
    if (props.item.videos[i].thumbnails && Array.isArray(props.item.videos[i].thumbnails) && props.item.videos[i].thumbnails.length > 0) {
      return props.item.videos[i].thumbnails[0].url // Return the first thumbnail URL
    } else {
      return image.src
    }
  } else {
    return image.src
  }
}

const windowWidth = ref(window.innerWidth)
const numberOfVideosToShow = computed(() => {
  if (windowWidth.value < 1550) {
    return 2 // Show 2 videos for small screens
  } else if (windowWidth.value < 1700) {
    return 3 // Show 3 videos for medium screens
  } else if (windowWidth.value < 2000) {
    return 4 // Show 4 videos for medium screens
  } else {
    return 5 // Show 5 videos for large screens
  }
})
const indicesToShow = computed(() => {
  return Array.from({ length: numberOfVideosToShow.value }, (_, i) => i)
})

function handleResize() {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style scoped lang="scss">
.folder {
  width: 100%;
  height: fit-content;
  cursor: pointer;

  &.frame {
    //padding: 0% 0% 2% 0%;
    //margin-bottom: 20px;
    border-radius: 6px 6px 6px 6px;
    @include hoverFrameEffect;

    .view {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3.81739px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      /* identical to box height */

      text-transform: uppercase;

      color: #ffffff;
      position: absolute;
      width: 87px;
      height: 30px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s;
    }

    .date {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      position: absolute;
      width: 87px;
      height: 30px;
      bottom: 0px;
      left: 15px;

      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      color: #ffffff;

      img {
        margin-right: 4px;
      }
    }
  }

  .small-videos {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 8px 0 7px 0;
    box-sizing: border-box;

    .thumbnails {
      width: 23.6%;
      padding-bottom: 16%;
      border-radius: 4px;

      .morevideos {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffff34;
        font-family: Nunito;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0px;
        text-transform: capitalize;
        color: #ffffff;
      }
    }
  }

  .head {
    padding: 16px;
  }

  &.list {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 30% 12% 36% 10% auto;
    align-items: center;

    &:hover {
      background-color: #f2f4fa;
    }

    .icon {
      cursor: pointer;
    }

    .title {
      display: flex;
      justify-content: left;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      letter-spacing: 0.1px;
      // text-transform: capitalize;

      /* body text */

      color: #4c4c66;

      .title-text {
        color: var(--body-text, #4c4c66);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: capitalize;
      }
    }

    .columns-text {
      overflow: hidden;
      color: var(--body-text, #4c4c66);
      text-overflow: ellipsis;
      /* P */
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.1px;
      text-transform: capitalize;
    }

    .videos-amount-number {
      display: flex;
      width: 22px;
      height: 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 30px;
      background: #dadbe8;
      border: 1px solid #dadbe8;
      border-radius: 30px;
      color: #4c4c66;
      text-align: center;
      font-family: Nunito;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      margin-right: 8px;
    }

    .videos-amount {
      /* white */
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      color: #4c4c66;
    }

    .flex-one {
      flex: 1;
    }

    .date {
      font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      color: #4c4c66;
    }

    .tags {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: Nunito;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      color: #4c4c66;
      background: none;
      border-radius: 0;
      margin-right: 20px;
      padding: 0;
      gap: 8px;

      .videos-amount-number {
        display: flex;
        width: 27px;
        height: 26px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: var(--title-2, #7d81b2);
        border: 1px solid #7d81b2;
        border-radius: 30px;
        color: var(--white, #fff);
        text-align: center;
        /* Small bold */
        font-family: Nunito;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
      }

      .tag {
        padding: 3px 20px;
        background-color: #f2f4fa;
        border-radius: 4px;
        margin: 0;
        display: flex;
        max-width: fit-content;
      }
    }
  }
}

.menu-settings {
  margin-top: 30px;
  padding-right: 25px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  img {
    position: absolute;
  }
}

.content {
  display: flex;
  flex-direction: column;

  &:hover {
    .additionalFolders {
      background-color: #7d81b2;
      border: 1px solid #7d81b2;
    }
  }

  .small-list-videos {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    box-sizing: border-box;
    column-gap: 10px;
    padding: 16px;

    .thumbnails {
      height: 53.034px;
      border-radius: 3px;
      padding-bottom: 16%;
      border-radius: 4px;
      padding: 0;
      width: 80px;

      .morevideos {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffff34;
        font-family: Nunito;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0px;
        text-transform: capitalize;
        color: #ffffff;
      }
    }

    :nth-child(1) {
      border-radius: 0 4px 4px 0;
    }

    :nth-child(4) {
      border-radius: 4px 0 0 4px;
    }
  }

  .folder-info {
    display: flex;
    flex-direction: column;

    .name {
      color: var(--white, #fff);
      /* H3 */
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }

    .description {
      color: var(--white, #fff);
      /* P */
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.1px;
      text-transform: capitalize;
    }

    .date {
      color: var(--white, #fff);
      /* Small */
      font-family: Nunito;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-transform: capitalize;
    }
  }
}

.thumbnails {
  width: 100%;
  padding-bottom: 60%;
  border-radius: 6px 6px 0px 0px;
  background: #f2f4fa;

  img.image {
    border-radius: 6px 6px 0 0;
  }

  &:hover {
    .view {
      opacity: 1;
    }
  }

  .info {
    top: 8px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      margin-bottom: 2px;
    }

    .params {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      color: #ffffff;
      display: flex;
      justify-content: left;
      align-items: center;

      .dot {
        display: flex;
        width: 19px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &.list {
    width: 80px;
    padding-bottom: 0;
    height: 53px;
    border-radius: 6px;
    margin-right: 32px;
    cursor: pointer;
  }
}

.row {
  display: flex;
}

.controls {
  display: flex;
  align-items: center;

  img {
    margin-left: 20px;
    cursor: pointer;
  }
}

.flex-between {
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.title {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #7d81b3;
  margin: 16px 0;
}

.categories {
  display: flex;
  align-items: center;
  margin: 0 16px 0 16px;

  .item {
    background: #dadbe8;
    border: 1px solid #dadbe8;
    border-radius: 2px;
    margin-right: 4px;
    padding: 3px 13px;
  }

  .additional {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    color: #4c4c66;
    margin-left: 10.5px;
  }
}

.folder-top {
  background: #f2f4fa;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 20px 20px 0 20px;
  margin: 0;
  aspect-ratio: 16/9;
  min-width: 399px;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* Added to make the ::after pseudo-element work */

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.folder-bottom {
  border: 1px solid #dadbe8;
  border-radius: 0px 0px 6px 6px;
  border-top: 0;

  .folder-counts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    padding: 32px 16px;
    column-gap: 32px;

    .count {
      display: flex;
      flex-direction: column;
      font-family: Nunito;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.3px;
      text-transform: capitalize;
      justify-content: center;
      text-align: center;
      margin: 0;

      .count-title {
        color: var(--body-text, #4c4c66);
        /* P */
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: capitalize;
      }
    }
  }
}

.folders {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 0px 0px 6px 6px;
  background: var(--filed, #f2f4fa);

  .item {
    border-radius: 2px;
    margin-right: 8px;
    padding: 3px 13px;
    border-radius: 4px;
    border: 1px solid var(--title-3, #dadbe8);
    background: var(--title-3, #dadbe8);
    color: var(--body-text, #4c4c66);
    text-align: center;
    /* P */
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
    text-transform: capitalize;
  }

  .additionalFolders {
    display: flex;
    width: 27px;
    height: 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: var(--title-2, #7d81b2);
    border: 1px solid #7d81b2;
    border-radius: 30px;
    color: var(--white, #fff);
    text-align: center;
    /* Small bold */
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
}

.listFolders {
  display: flex;
  align-items: center;

  .item {
    border-radius: 2px;
    margin-right: 8px;
    padding: 3px 13px;
    border-radius: 4px;
    border: 1px solid var(--title-3, #dadbe8);
    background: var(--title-3, #dadbe8);
    color: #4c4c66;
    text-align: center;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
    text-transform: capitalize;
  }

  .additionalFolders {
    display: flex;
    width: 27px;
    height: 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: var(--title-2, #7d81b2);
    border-radius: 30px;
    color: var(--white, #fff);
    text-align: center;
    /* Small bold */
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
}

// .content {
//   div {
//     margin: 0;
//   }
// }
</style>
