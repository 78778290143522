<template>
  <div ref="dataViewRow" class="domain">
    <div class="domain-box" :style="`grid-template-columns:  ${columnsGridTemplate(props.columns)};`" @click="selectedDomain = item">
      <div v-if="item.domain.length < 22" class="data" :class="shouldHideOnBreakpoint(props.columns, 'domain')">
        {{ item.domain }}
      </div>
      <div v-else class="data" :class="shouldHideOnBreakpoint(props.columns, 'domain')">
        <Tooltip :placement="'top'" :content="item.domain" :textLength="20" />
      </div>
      <div class="data" :class="shouldHideOnBreakpoint(props.columns, 'created_at')">
        {{ getUTCDateString(item.created_at) }}
      </div>
      <div class="buttons" :class="shouldHideOnBreakpoint(props.columns, 'actions')">
        <ActionButtons :actions="actions" :is-visible="isRowHovered" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'
import { useElementHover } from '@vueuse/core'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { getUTCDateString } from '@/services/utils'
import { Tooltip } from '@/components/common'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { openModalCustomConfirmation } from '@/services/utils'
import { ModalType } from '@/components/modals/modals-util'
import { CRUD, DataActions, DataType } from '@/interfaces'
import { apiService } from '@/services'
import { EXCLUDED_DOMAINS_ACTIONS_INJECTION_KEY } from '@/constants'

const selectedDomain = ref()
const props = defineProps(['item', 'columns'])
const store = useStore()
const { t } = useI18n()
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const modalType = ModalType.DOMAINS_DELETE_EXCLUDE_DOMAIN_MODAL
const dataActions = inject<DataActions>(EXCLUDED_DOMAINS_ACTIONS_INJECTION_KEY)

const actions = [
  {
    label: 'delete',
    handler: openDeleteExcludedDomain,
    translationKey: 'delete'
  }
]

function openDeleteExcludedDomain() {
  openModalCustomConfirmation({ action: CRUD.Delete, type: DataType.ExcludedDomains, onConfirm: deleteExcludedDomain }, modalType)
}

function deleteExcludedDomain() {
  apiService.content
    .deleteExcludedDomain(props.item.domain)
    .then(() => {
      store.dispatch('messages/addMessage', {
        message: t('domain-deleted'),
        type: 'success'
      })
      if (dataActions?.refreshData) dataActions.refreshData()
    })
    .catch((error) => {
      store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    })
}
</script>

<style lang="scss" scoped>
.trash {
  width: 22px;
  height: 22px;

  &:hover {
    filter: $truvid-filter-effect;
  }
}

.domain {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .domain-box {
    display: grid;
    grid-template-columns: 30% 64% auto;
    grid-template-rows: 100%;
    height: 100%;
    justify-content: left;
    align-items: center;
    flex-flow: row;
    align-items: center;

    .data {
      color: #4c4c66;
      padding: 16px;
    }

    :nth-child(1) {
      display: flex;
      align-items: center;
    }

    :nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;

      img {
        cursor: pointer;
      }
    }

    .circle {
      box-sizing: border-box;
      width: 32px;
      height: 32px;
      border: 1px solid #dadbe8;
      border-radius: 100%;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 15px;
      }
    }

    &:hover {
      background-color: #f3f4fa;
    }
  }
}
</style>
