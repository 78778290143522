import { DataViewType } from '@/interfaces'
import type { Countries, Languages } from '@/services/general/types'
import type { LoggedInUserRoles } from '@/services/organization-user/types'
import type { VideoCategories } from '@/services/types'
import { getRouteHash, isRouteHasCachedViewType } from '@/services/utils'

export interface UserPreferences {
  viewTypes: { [routeName: string]: DataViewType }
  pageLanguage: string
  firstLogin: boolean
  isUserLoggedIn: boolean
  reportType: string
}

export interface PreferencesPerUser {
  [id: number]: UserPreferences
}

export interface Cache {
  preferencesPerUser: PreferencesPerUser
  languages: Languages
  countries: Countries
  categories: VideoCategories
  availableRoles: LoggedInUserRoles
  availableRolesAgency: LoggedInUserRoles
  currentViewType: DataViewType
}

export const DEFAULT_VIEW_TYPE_KEY = '*'

export const cache = {
  namespaced: true,
  state: () => ({
    languages: {},
    countries: {},
    categories: {},
    preferencesPerUser: {},
    availableRoles: {},
    availableRolesAgency: {},
    currentViewType: ''
  }),
  getters: {
    getViewType: (state, _, rootState) => () => {
      const id = rootState.user.user.id
      if (!state.preferencesPerUser[id]) {
        state.preferencesPerUser[id] = newUser()
      }
      if (isRouteHasCachedViewType()) {
        const routeHash = getRouteHash()
        const viewTypeKeys = Object.keys(state.preferencesPerUser[id].viewTypes)
        const viewType = viewTypeKeys.find((k) => routeHash === k)
        state.currentViewType = state.preferencesPerUser[id].viewTypes[viewType ?? DEFAULT_VIEW_TYPE_KEY]
      }
      return state.currentViewType
    },
    getReportType: (state, _, rootState) => () => {
      const id = rootState.user.user.id
      if (!state.preferencesPerUser[id]) {
        state.preferencesPerUser[id] = newUser()
      }
      return state.preferencesPerUser[id].reportType
    },
    getLanguages: (state) => () => {
      return state.languages
    },
    getPageLanguage: (state, _, rootState) => () => {
      const id = rootState.user.user.id
      if (id < 0) {
        return 'en'
      }
      if (!state.preferencesPerUser[id]) {
        state.preferencesPerUser[id] = newUser()
      }
      return state.preferencesPerUser[id].pageLanguage
    },
    getCountries: (state) => () => {
      return state.countries
    },
    getCategories: (state) => () => {
      return state.categories
    },
    getFirstLogin: (state, _, context) => () => {
      const id = context.user.user.id
      if (id === -1) {
        return false
      }
      if (!state.preferencesPerUser[id]) {
        state.preferencesPerUser[id] = newUser()
      }
      if (!state.preferencesPerUser[id].firstLogin) {
        return state.preferencesPerUser[id].firstLogin
      } else {
        state.preferencesPerUser[id].firstLogin = false
        return true
      }
    },
    getIsUserLoggedIn: (state, _, context) => () => {
      const id = context.user.user.id
      if (id === -1) {
        return false
      }
      if (!state.preferencesPerUser[id]) {
        state.preferencesPerUser[id] = newUser()
      }
      return state.preferencesPerUser[id].isUserLoggedIn
    },
    getAvailableRoles: (state) => () => {
      return state.availableRoles
    },
    getAvailableRolesAgency: (state) => () => {
      return state.availableRolesAgency
    }
  },
  actions: {
    setViewType({ rootState, commit, state }, type: DataViewType) {
      const id = rootState.user.user.id
      if (!state.preferencesPerUser[id]) {
        state.preferencesPerUser[id] = newUser()
      }
      state.currentViewType = type
      commit('writeViewType', { id, type })
    },
    setPageLanguage({ rootState, commit, state }, language: string) {
      const id = rootState.user.user.id
      if (!state.preferencesPerUser[id]) {
        state.preferencesPerUser[id] = newUser()
      }
      commit('writePageLanguage', { id, language })
    },
    setReportType({ rootState, commit, state }, type: string) {
      const id = rootState.user.user.id
      if (!state.preferencesPerUser[id]) {
        state.preferencesPerUser[id] = newUser()
      }
      commit('writeReportType', { id, type })
    },
    setFirstLogin({ rootState, commit, state }, firstLogin: boolean) {
      const id = rootState.user.user.id
      if (!state.preferencesPerUser[id]) {
        state.preferencesPerUser[id] = newUser()
      }
      commit('writeFirstLogin', { id, firstLogin })
    },
    setIsUserLoggedIn({ rootState, commit, state }, isUserLoggedIn: boolean) {
      const id = rootState.user.user.id
      if (!state.preferencesPerUser[id]) {
        state.preferencesPerUser[id] = newUser()
      }
      commit('writeIsUserLoggedIn', { id, isUserLoggedIn })
    }
  },
  mutations: {
    writeReportType(state: Cache, data: { id: number; type: string }) {
      state.preferencesPerUser[data.id].reportType = data.type
    },
    writePageLanguage(state: Cache, data: { id: number; language: string }) {
      state.preferencesPerUser[data.id].pageLanguage = data.language
    },
    writeViewType(state: Cache, data: { id: number; type: DataViewType }) {
      state.preferencesPerUser[data.id].viewTypes[getRouteHash()] = data.type
    },
    writeFirstLogin(state: Cache, data: { id: number; firstLogin: boolean }) {
      state.preferencesPerUser[data.id].firstLogin = data.firstLogin
    },
    writeIsUserLoggedIn(state: Cache, data: { id: number; isUserLoggedIn: boolean }) {
      state.preferencesPerUser[data.id].isUserLoggedIn = data.isUserLoggedIn
    },
    setLanguages(state: Cache, languages: Languages) {
      Object.assign(state.languages, languages)
    },
    setCategories(state: Cache, categories: VideoCategories) {
      Object.assign(state.categories, categories)
    },
    setCountries(state: Cache, countries: Countries) {
      Object.assign(state.countries, countries)
    },
    setAvailableRoles(state: Cache, availableRoles: LoggedInUserRoles) {
      Object.assign(state.availableRoles, availableRoles)
    },
    setAvailableRolesAgency(state: Cache, availableRolesAgency: LoggedInUserRoles) {
      Object.assign(state.availableRolesAgency, availableRolesAgency)
    },
    clearCachedRoles(state: Cache) {
      state.availableRolesAgency.available_roles = []
      state.availableRolesAgency.disabled_roles = []
      state.availableRoles.available_roles = []
      state.availableRoles.disabled_roles = []
    }
  }
}

function newUser() {
  return {
    viewTypes: { [`${DEFAULT_VIEW_TYPE_KEY}`]: DataViewType.Grid },
    pageLanguage: 'en',
    firstLogin: true,
    isUserLoggedIn: false
  }
}
