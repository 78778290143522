import { useViewPort } from '@/composables'
import { DataType, DataViewBreakpoints } from '@/interfaces'
import store from '@/store'
import { computed } from 'vue'

// Define the types
interface Column {
  name: string
  position?: number
  priority?: number
}

// interface Breakpoints {
//   [key: string]: number
// }

// Assuming these functions are defined somewhere in your codebase
const { getCurrentBreakpoint, getCurrentViewPortWidth } = useViewPort()
export const breakpointDataViewType = () => {
  switch (store.state.dataViewType) {
    case DataType.ExcludedDomains:
      return DataViewBreakpoints.ExcludedDomains
    case DataType.OfferedPackages:
      return DataViewBreakpoints.OfferedPackages
    case DataType.Video:
      return DataViewBreakpoints.Video
    case DataType.Folder:
      return DataViewBreakpoints.Folder
    case DataType.Package:
      return DataViewBreakpoints.Package
    case DataType.Playlist:
      return DataViewBreakpoints.Playlist
    case DataType.Widget:
      return DataViewBreakpoints.Widget
    case DataType.MyDomains:
      return DataViewBreakpoints.MyDomains
    case DataType.Users:
      return DataViewBreakpoints.Users
    case DataType.ContentSources:
      return DataViewBreakpoints.ContentSources
    case DataType.AgencyAccounts:
      return DataViewBreakpoints.AgencyAccounts
    case DataType.PairedUrls:
      return DataViewBreakpoints.PairedUrls
    default:
      throw new Error('No data type was passed as prop to DataView component')
  }
}

export const columnsGridTemplate = computed(() => {
  return (columns) => {
    if (!columns) {
      return
    }
    const breakpoints = breakpointDataViewType()
    const currentBreakpoint = getCurrentBreakpoint()
    const maxColumns = breakpoints[currentBreakpoint] >= columns?.length ? columns.length : breakpoints[currentBreakpoint] || columns?.length - 1 // Show all columns if breakpoint not found
    const visibleColumns = columns?.filter((col) => col && col?.position !== undefined).sort((a, b) => a.position - b.position) ?? []
    // Separate columns with priority 0 and 1
    const priorityZeroColumns = visibleColumns.filter((col) => col?.priority === 0)
    const priorityOneColumns = visibleColumns.filter((col) => col?.priority === 1)

    // Initialize adjustedColumns with priority 1 and 0 columns
    let adjustedColumns = []
    if (getCurrentBreakpoint() === 'md' || getCurrentBreakpoint() === 'sm' || getCurrentBreakpoint() === 'xs') {
      adjustedColumns = [...priorityOneColumns, ...priorityZeroColumns]
    } else {
      adjustedColumns = [...priorityOneColumns, ...priorityZeroColumns]
      // Add columns with priority higher than 1 if space permits
      for (let priority = 2; priority <= Math.max(...visibleColumns.map((col) => col?.priority)); priority++) {
        const priorityColumns = visibleColumns.filter((col) => col?.priority === priority)
        if (adjustedColumns.length + priorityColumns.length <= maxColumns) {
          adjustedColumns = [...adjustedColumns, ...priorityColumns]
        }
      }
    }
    const viewportWidth = getCurrentViewPortWidth()
    const numColumns = adjustedColumns.length
    if (store.state.dataViewType === DataType.ContentSources) {
      return `repeat(${numColumns}, minmax(0, 1fr))`
    } else if (store.state.dataViewType === DataType.Video) {
      if (viewportWidth >= 1400) {
        const firstColumnWidth = 30
        const remainingColumnWidth = (100 - firstColumnWidth - 5) / (numColumns - 2)
        const actionButtonsWidth = 5
        return `${firstColumnWidth}%  repeat(${numColumns - 2}, ${remainingColumnWidth}%) ${actionButtonsWidth}%`
      }

      const firstColumnWidth = 40
      const remainingColumnWidth = (100 - firstColumnWidth) / (numColumns - 1)
      return `${firstColumnWidth}% repeat(${numColumns - 1}, ${remainingColumnWidth}%)`
    } else if (store.state.dataViewType === DataType.Widget) {
      const sortedColsByPos = adjustedColumns.sort((a, b) => a.position - b.position)
      const gridColsWidth = sortedColsByPos.map((item) => (item.name === 'widget_name' ? 'auto' : `${item.width}%`)).join(' ')
      return gridColsWidth
    } else if (store.state.dataViewType === DataType.Folder) {
      if (viewportWidth >= 1400) {
        const firstColumnWidth = 25
        const secondColumnWidth = 25
        const actionButtonsWidth = 5
        const remainingColumnWidth = (100 - firstColumnWidth - secondColumnWidth - actionButtonsWidth) / (numColumns - 3)
        return `${firstColumnWidth}%  repeat(${numColumns - 3}, ${remainingColumnWidth}%) ${secondColumnWidth}% ${actionButtonsWidth}%`
      }

      const firstColumnWidth = 40
      const remainingColumnWidth = (100 - firstColumnWidth) / (numColumns - 1)
      return `${firstColumnWidth}% repeat(${numColumns - 1}, ${remainingColumnWidth}%)`
    } else if (store.state.dataViewType === DataType.Playlist) {
      if (viewportWidth <= 2000) {
        const firstColumnWidth = 15
        const actionButtonsWidth = 5
        const remainingColumnWidth = (100 - firstColumnWidth - actionButtonsWidth) / (numColumns - 2)
        return `${firstColumnWidth}% repeat(${numColumns - 2}, ${remainingColumnWidth}%) ${actionButtonsWidth}%`
      }

      // If viewportWidth > 1800
      const firstColumnWidth = 5
      const remainingColumnWidth = (100 - firstColumnWidth) / (numColumns - 1)
      return `${firstColumnWidth}% repeat(${numColumns - 1}, ${remainingColumnWidth}%)`
    } else if (store.state.dataViewType === DataType.Package) {
      if (viewportWidth >= 1400) {
        const firstColumnWidth = 25
        const secondColumnWidth = 25
        const actionButtonsWidth = 5
        const remainingColumnWidth = (100 - firstColumnWidth - secondColumnWidth - actionButtonsWidth) / (numColumns - 3)
        return `${firstColumnWidth}%  repeat(${numColumns - 3}, ${remainingColumnWidth}%) ${secondColumnWidth}% ${actionButtonsWidth}%`
      }

      const firstColumnWidth = 40
      const remainingColumnWidth = (100 - firstColumnWidth) / (numColumns - 1)
      return `${firstColumnWidth}% repeat(${numColumns - 1}, ${remainingColumnWidth}%)`
    } else {
      if (numColumns <= 3) {
        const firstColumnWidth = 40
        const remainingColumnWidth = (100 - firstColumnWidth) / (numColumns - 1)
        return `${firstColumnWidth}% repeat(${numColumns - 1}, ${remainingColumnWidth}%)`
      } else if (numColumns <= 5) {
        const firstColumnWidth = 25
        const remainingColumnWidth = (100 - firstColumnWidth) / (numColumns - 1)
        return `${firstColumnWidth}% repeat(${numColumns - 1}, ${remainingColumnWidth}%)`
      } else {
        const firstColumnWidth = 18
        const remainingColumnWidth = (100 - firstColumnWidth) / (numColumns - 1)
        return `${firstColumnWidth}% repeat(${numColumns - 1}, ${remainingColumnWidth}%)`
      }
    }
  }
})

export const shouldHideOnBreakpoint = computed(() => {
  return (columns: Column[], columnName: string): Record<string, boolean> => {
    if (!columns) {
      return
    }
    const breakpoints = breakpointDataViewType()
    const currentBreakpoint = getCurrentBreakpoint()
    let maxColumns = columns.length // Default to all columns if no breakpoints are defined

    if (breakpoints && breakpoints[currentBreakpoint]) {
      maxColumns = breakpoints[currentBreakpoint]
    }

    // Filter and sort columns based on position
    const visibleColumns = columns.filter((col) => col && col.position !== undefined).sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
    // Separate columns by priority
    const priorityOneColumns = visibleColumns.filter((col) => col.priority === 1)
    const priorityZeroColumns = visibleColumns.filter((col) => col.priority === 0)

    // Determine otherColumns based on the current breakpoint
    let otherColumns = []
    if (breakpoints[currentBreakpoint] >= columns.length) {
      otherColumns = visibleColumns.filter((col) => col.position !== 1 && col.position !== 0).sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
    } else {
      otherColumns = visibleColumns.filter((col) => col.priority !== 1 && col.priority !== 0).sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
    }

    let adjustedColumns = []

    if (getCurrentBreakpoint() === 'md' || getCurrentBreakpoint() === 'sm' || getCurrentBreakpoint() === 'xs') {
      adjustedColumns = [...priorityOneColumns, ...priorityZeroColumns]
    } else {
      // Merge columns maintaining the order
      adjustedColumns = [...priorityOneColumns, ...otherColumns, ...priorityZeroColumns]
      // Add columns with priority higher than 1 if space permits
      for (let priority = 2; priority <= Math.max(...visibleColumns.map((col) => col.priority ?? 0)); priority++) {
        const priorityColumns = visibleColumns.filter((col) => col.priority === priority)
        if (adjustedColumns.length + priorityColumns.length <= maxColumns) {
          adjustedColumns = [...adjustedColumns, ...priorityColumns]
        }
      }
    }

    // Calculate final columns
    const finalColumns = adjustedColumns
      .slice(0, maxColumns - 1)
      .sort((a, b) => a.position - b.position)
      .concat(priorityZeroColumns)
    const column = finalColumns.find((col) => col.name === columnName)

    if (column) {
      let hidden = false
      let orderClass = `order-${maxColumns}`
      if (column.name === 'actions') {
        orderClass = column?.position !== undefined ? `order-${columns.length}` : ''
      } else {
        orderClass = column?.position !== undefined ? (breakpoints[currentBreakpoint] >= columns.length ? `order-${column.position}` : `order-${column.position}`) : ''
      }
      // Check if the column should be hidden based on its position
      if (breakpoints[currentBreakpoint] >= columns.length) {
        if (column.position !== undefined && column.position >= maxColumns) {
          hidden = true
        }
      } else {
        if (column.priority !== undefined && column.priority >= maxColumns) {
          if (column.name !== 'actions') {
            hidden = true
          }
        }
      }
      return {
        hidden: hidden,
        [orderClass]: true
      }
    } else {
      // console.warn('Column not found', columnName)
      return { hidden: true }
    }
  }
})

export const columnsToDisplay = computed(() => {
  return (columns) => {
    return columns.filter((column) => !column.breakpoints || !column.breakpoints.includes(getCurrentBreakpoint()))
  }
})
