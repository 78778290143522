import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const excludedDomainsCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'domain',
      title: 'settings-content-excluded-domains-domain',
      width: 30,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 1,
      priority: 1
    },
    {
      name: 'created_at',
      title: 'create-date',
      width: 64,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 2,
      priority: 2
    },
    {
      name: 'actions',
      title: '',
      width: 'auto',
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 0,
      priority: 0
    }
  ]
})

export default excludedDomainsCols
