import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const folderCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'name',
      title: 'content-folder-name',
      width: 30,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 1,
      priority: 1
    },
    {
      name: 'number_of_videos',
      title: 'content-number-of-videos',
      width: 12,
      alignment: ListColumnAlignment.Center,
      sortable: false, // not supported for now,
      position: 2,
      priority: 2
    },
    {
      name: 'create_date',
      title: 'content-created-at',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 3,
      priority: 3
    },
    {
      name: 'actions',
      title: '',
      width: 1,
      alignment: ListColumnAlignment.Center,
      position: 0,
      priority: 0
    }
  ]
})

export default folderCols
