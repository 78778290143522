import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const videoCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'title',
      title: 'content-video-title',
      width: 33,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      offset: 140,
      position: 1,
      priority: 1
    },
    {
      name: 'upload_date',
      title: 'content-uploaded-date',
      width: 11,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 6,
      priority: 2
    },
    {
      name: 'private',
      title: 'content-video-exposure',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 4,
      priority: 5
    },
    {
      name: 'payment',
      title: 'content-payment-type',
      width: 12,
      alignment: ListColumnAlignment.Center,
      position: 5,
      priority: 6
    },
    {
      name: 'language_2_letters',
      title: 'settings-content-content-sources-language',
      width: 10,
      alignment: ListColumnAlignment.Center,
      position: 3,
      priority: 4
    },
    {
      name: 'duration_in_ms',
      title: 'library-filters-duration',
      width: 13,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 2,
      priority: 3
    },
    {
      name: 'actions',
      title: '',
      width: 15,
      alignment: ListColumnAlignment.Center,
      position: 0,
      priority: 0
    }
  ]
})

export default videoCols
