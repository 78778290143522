<template>
  <div>
    <div
      :class="{
        flex: true,
        gap: !separator,
        row: props.layout === InputLayout.Horizontal,
        mobile: props.layout === InputLayout.Mobile
      }"
    >
      <div v-for="(radio, index) in props.radioGroup" :key="radio.id" class="radio-container">
        <div class="radio-button">
          <template v-if="labelPosition === LabelPosition.PRE">
            <label :class="{ bold: inputValueLocal === radio.value }" :for="radio.id">{{ radio.label }}</label>
            <TextIconTooltip v-if="radio.tooltip" :tooltip="radio.tooltip" />
          </template>
          <input
            :id="radio.id"
            v-model="inputValueLocal"
            type="radio"
            :value="radio.value"
            :name="'group' + (groupCustomId ? groupCustomId : customId)"
            :class="[labelPosition]"
            @change="handleEvents"
          >
          <template v-if="labelPosition === LabelPosition.POST">
            <label :for="radio.id">{{ radio.label }}</label>
            <TextIconTooltip v-if="radio.tooltip" :tooltip="radio.tooltip" />
          </template>
        </div>
        <div v-if="radio.description" class="radio-description-container">
          {{ radio.description }}
        </div>
        <hr v-if="separator && index !== props.radioGroup.length - 1">
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { RadioGroupInput, InputLayout, InputIconActionType } from '@/interfaces/index'
import { uid } from 'uid'
import TextIconTooltip from '@/components/common/tooltips/TextIconTooltip.vue'
import { LabelPosition } from '@/components/common/custom-inputs/types'

const customId = ref(uid())
const inputValueLocal = ref(null)
const props = defineProps<{
  modelValue: string | number | object | boolean | null
  radioGroup?: RadioGroupInput[] | null
  layout?: InputLayout
  iconPre?: string | null
  iconPost?: string | null
  iconAction?: InputIconActionType | null
  labelPosition?: LabelPosition | null
  separator?: boolean | null
  groupCustomId?: string | null
}>()

onMounted(() => {
  inputValueLocal.value = props.modelValue
})

watch(
  () => props.modelValue,
  () => {
    inputValueLocal.value = props.modelValue
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | object | null): void
  (e: 'iconActionEmit', value: string): void
  (e: 'validate')
  (e: 'radioChange', value: string): void
}>()

function handleEvents() {
  emit('update:modelValue', inputValueLocal.value)
  emit('validate')
  emit('radioChange', inputValueLocal.value)
}
</script>

<style scoped lang="scss">
.radio-container {
  width: 100%;
}

.radio-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
}

.bold {
  font-weight: 700;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.row {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &.gap {
    gap: 8px;
  }
}

hr {
  opacity: 0.4;
  border: 1px solid #e5e5e5;
  margin: 0.6rem 0;
}

.radio-description-container {
  margin: 16px 0;
}

label {
  cursor: pointer;
  font-size: 14px;
}

.mobile {
  color: var(--dark-gray, #6f6e71);

  /* H4 */
  label {
    font-size: 16px;
    font-style: normal;
    flex-direction: column;
    width: calc(100% - 60px);
    display: inline-block;
    height: 100%;
    line-height: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .radio-container {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-bottom: 1px solid var(--bold-filed, #e8ebf4);

    .radio-button {
      display: flex;
      width: 100%;
      height: 70px;
    }

    input {
      float: right;
      margin-left: 42px;
    }
  }
}
</style>
