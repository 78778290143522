import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const userCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'first_name',
      title: 'settings-publisher-users-name',
      width: 19,
      alignment: ListColumnAlignment.Left,
      sortable: true,
      position: 1,
      priority: 1
    },
    {
      name: 'user_permissions',
      title: 'settings-publisher-users-permission',
      width: 21,
      alignment: ListColumnAlignment.Left,
      sortable: false,
      position: 2,
      priority: 2
    },
    {
      name: 'is_active',
      title: 'settings-publisher-users-activation',
      width: 12,
      alignment: ListColumnAlignment.Left,
      sortable: true,
      position: 3,
      priority: 3
    },
    {
      name: 'last_login',
      title: 'settings-publisher-users-last-active',
      width: 12,
      alignment: ListColumnAlignment.Left,
      sortable: true,
      position: 4,
      priority: 4
    },
    {
      name: 'created_at',
      title: 'create-date',
      width: 14,
      alignment: ListColumnAlignment.Left,
      sortable: true,
      position: 5,
      priority: 5
    },
    {
      name: 'actions',
      title: '',
      width: 'auto',
      alignment: ListColumnAlignment.Left,
      sortable: false,
      position: 1,
      priority: 0
    }
  ]
})

export default userCols
