import IconCreateAccount from '@/assets/dashboard/create-account.svg'
import IconCreateChannel from '@/assets/dashboard/create-channel.svg'
import IconCreatePackage from '@/assets/dashboard/create-package.svg'
import IconCreatePlaylist from '@/assets/dashboard/create-playlist.svg'
import IconCreateWidget from '@/assets/dashboard/create-widget.svg'
import IconGetHelp from '@/assets/dashboard/get-help.svg'
import IconImportStream from '@/assets/dashboard/import-stream.svg'
import IconReviewKnowledge from '@/assets/dashboard/review-knowledge.svg'
import IconReviewReports from '@/assets/dashboard/review-reports.svg'
import IconUploadVideo from '@/assets/dashboard/upload-video.svg'
import { KeyMap } from '@/constants'
import languageCodes from '@/data/languages'
import i18n from '@/i18n'
import { ContentPath, CustomAxiosError, DateRangeNames, LoadingState, ReportColumns, VideoStatus, WelcomeCard } from '@/interfaces'
import router from '@/router'
import { apiService } from '@/services'
import { HTTP_STATUS_OK, PASTEL_COLORS, TRUVID_GENERAL_FOLDER, VIDEO_EXTENSIONS, TRUVID_REGISTRATION_STEPS, NOT_AVAILABLE, TRUVID_TIME_ZONES } from '@/services/constants'
import { ReportTable, TableColumn } from '@/services/report/types'
import store from '@/store'
import { DateTimeFormatOptions } from '@intlify/core-base'
import { useDebounceFn, useThrottleFn } from '@vueuse/core'
import { add, endOfDay, endOfYesterday, format, setDate, startOfDay, startOfMonth, startOfYesterday } from 'date-fns'
import { UAParser } from 'ua-parser-js'
import { uid } from 'uid'
import { useI18n } from 'vue-i18n'
import { RouteLocationNormalizedLoaded } from 'vue-router'
import { UserRolesNames } from './organization-user/constants'
import type { Folder } from '@/services/folder/types'
import type { Playlist, PlaylistInput } from '@/services/playlist/types'
import type { UploadVideo } from '@/services/videos/types'
import { compare } from 'compare-versions'
import countryCode from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import CustomConfirmation, { ModalProps } from '@/components/modals/custom-modal/modal-action-components/CustomConfirmation.vue'
import { markRaw } from 'vue'
import { ResizeHandleTypeRoutes } from '@/composables/viewPortResizeHandleType'
import { ModalType } from '@/components/modals/modals-util'

countryCode.registerLocale(enLocale)

export function transfromReportsColumns(columns: ReportColumns): TableColumn[] {
  const data = []
  if (columns && columns.columns) {
    columns.columns.forEach((column) => {
      const columnData = {
        headerName: 'reports-column-' + column.column_name,
        field: column.column_name,
        sortable: column.orderable,
        isKey: column.is_default,
        is_selected: column.is_default,
        is_default: column.is_default,
        searchable: column.searchable
      }
      data.push(columnData)
    })
  }
  return data
}

export function transfromReportsRows(table: ReportTable) {
  const rows = []
  if (table && table.data) {
    table.data.forEach((element) => {
      const row = {}
      table.columns.forEach((column, index) => {
        row[column.column_name] = element[index]
      })
      rows.push(row)
    })
  }
  return rows
}

//amount2 - i.e server calls
//amount1 - i.e revenue
export function transformDashboardGraphData(data, type: string[], reverse = false) {
  const graphData = []
  if (data !== null) {
    Object.keys(data[type[0]]).forEach((key) => {
      let date = key.includes(':') ? key.replace(' ', 'T') : key + 'T00:00:00'
      const localOffset = new Date().getTimezoneOffset()
      date = date + (localOffset > 0 ? '-' : '+') + String(Math.floor(Math.abs(localOffset) / 60)).padStart(2, '0') + ':' + String(Math.abs(localOffset) % 60).padStart(2, '0')
      graphData.push({
        date: new Date(date),
        amount1: parseFloat(!reverse ? data[type[0]][key].toFixed(2) : data[type[1]][key].toFixed(2)),
        amount2: parseFloat(!reverse ? data[type[1]][key].toFixed(2) : data[type[0]][key].toFixed(2))
      })
    })
  }
  return graphData.length > 0 ? graphData : null
}

export function getVideoPositionString(position: number) {
  let time: string
  if (position === 0) {
    return '00:00:00.00'
  }
  if (position > 60 * 60) {
    time = Math.floor(position / 3600)
      .toString()
      .padStart(2, '0')
    position = position - Math.floor(position / 3600) * 3600
  } else {
    time = '00'
  }

  if (position > 60) {
    time +=
      ':' +
      Math.floor(position / 60)
        .toString()
        .padStart(2, '0')
    position = position - Math.floor(position / 60) * 60
  } else {
    time += ':00'
  }
  const seconds = Math.round(position * 100)
    .toString()
    .padStart(4, '0')

  time += ':' + seconds.substring(0, 2) + '.' + seconds.substring(2)

  return time
}

export function lerpColor(pFrom: number, pTo: number, pRatio: number) {
  const ar = (pFrom & 0xff0000) >> 16
  const ag = (pFrom & 0x00ff00) >> 8
  const ab = pFrom & 0x0000ff
  const br = (pTo & 0xff0000) >> 16
  const bg = (pTo & 0x00ff00) >> 8
  const bb = pTo & 0x0000ff
  const rr = ar + pRatio * (br - ar)
  const rg = ag + pRatio * (bg - ag)
  const rb = ab + pRatio * (bb - ab)

  return `#${((rr << 16) + (rg << 8) + (rb | 0)).toString(16).padStart(6, '0').slice(-6)}`
}

export function numberCompact(num: number): string {
  if (typeof num === 'number') {
    const formatter = Intl.NumberFormat('en', { notation: Math.abs(num) / 1000 > 1 ? 'compact' : 'standard' })
    return formatter.format(Number(num.toFixed(2)))
  }
  return '0'
}

export function commaSeparateNumber(val) {
  // remove sign if negative
  let sign = 1
  if (val < 0) {
    sign = -1
    val = -val
  }

  if (isNaN(parseFloat(val))) {
    return ''
  }

  // trim the number decimal point if it exists
  let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString()

  while (/(\d+)(\d{3})/.test(num.toString())) {
    // insert comma to 4th last position to the match number
    num = num.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
  }

  // add number after decimal point
  if (val.toString().includes('.')) {
    num = num + '.' + val.toString().split('.')[1].slice(0, 2)
  }
  // return result with - sign if negative
  return sign < 0 ? '-' + num : num
}

export function debounce(fn, wait) {
  return useDebounceFn(fn, wait)
}

export function throttle(value, delay?: number) {
  return useThrottleFn(value, delay)
}

export function getDateTimeByLocale(date: string): string {
  return Intl.DateTimeFormat(navigator.language).format(new Date(date)).toString()
}

// Helper function to extract date parts for the target timezone
function getDatePartsForTimeZone(utcTimestamp: number, timeZone: string) {
  const options = {
    timeZone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  }
  const dateParts = new Intl.DateTimeFormat('en-US', options).formatToParts(new Date(utcTimestamp));
  const year = parseInt(dateParts.find(p => p.type === 'year').value);
  const month = parseInt(dateParts.find(p => p.type === 'month').value) - 1 // Month is 0-indexed
  const day = parseInt(dateParts.find(p => p.type === 'day').value);
  const hour = parseInt(dateParts.find(p => p.type === 'hour').value)
  const minute = parseInt(dateParts.find(p => p.type === 'minute').value)
  return { year, month, day, hour, minute }
}

// Function to create a Date object for a given dateTime string, time zone
export function createDateForTimeZone(dateTime: string, timeZone: string): Date {
  if (dateTime.length === 10) {
    dateTime += 'T00:00'
  }
  const localDate = new Date(dateTime)
  const utcTimestamp = localDate.getTime() + (localDate.getTimezoneOffset() * 60000)
  const { year, month, day, hour, minute } = getDatePartsForTimeZone(utcTimestamp, timeZone)
  return new Date(Date.UTC(year, month, day, hour, minute))
}

// Updated function to convert an existing Date object to a different time zone with option for start or end of the day
export function convertDateForTimeZone(localDate: Date, timeZone: string, isEndOfDay = false): Date {
  const utcTimestamp = localDate.getTime() + (localDate.getTimezoneOffset() * 60000)
  const { year, month, day, hour, minute } = getDatePartsForTimeZone(utcTimestamp, timeZone)
  return new Date(year, month, day, isEndOfDay ? 23 : hour, isEndOfDay ? 59 : minute)
}

export function formatDateDateMonthYear(isoDate: string): string {
  const date = new Date(isoDate)
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export function getUTCDateString(date: string): string {
  const d = new Date(date)
  const year = d.getUTCFullYear().toString().length > 2 ? d.getUTCFullYear().toString().slice(2, 4) : d.getUTCFullYear().toString().padStart(2, '0')
  const month = (d.getMonth() + 1).toString().padStart(2, '0')
  const day = d.getDate().toString().padStart(2, '0')
  return `${month}/${day}/${year}`
}

export function getDateString(date: string): string {
  const d = new Date(date)
  return getDateStringByDate(d)
}

export function getDateStringReports(date: string, groupBy: string): string {
  const [datePart, timePart] = date.split(' ')
  const [year, month, day] = datePart.split('-')
  const shortYear = year.slice(-2)
  const time = timePart ? timePart.slice(0, 5) : ''

  switch (groupBy) {
    case 'month':
      return `${month}/${shortYear}`
    case 'day':
      return day ? `${month}/${day}/${shortYear}` : `${month}/${year}`
    case 'hour':
      return day ? `${month}/${day}/${shortYear} ${time}` : `${month}/${year}`
    default:
      return ''
  }
}

export function getDateStringByDate(d: Date): string {
  const year = d.getFullYear().toString().length > 2 ? d.getFullYear().toString().slice(2, 4) : d.getFullYear().toString().padStart(2, '0')
  const month = (d.getMonth() + 1).toString().padStart(2, '0')
  const day = d.getDate().toString().padStart(2, '0')
  return `${month}/${day}/${year}`
}

export function getDateStringForGraphBubble(d: Date, rangeDates: string[]): string {
  const year = d.getFullYear().toString()
  const month = capitalize(i18n.global.t(monthKeys[d.getMonth()]))
  const day = d.getDate().toString().padStart(2, '0')
  const hours = d.getHours().toString().padStart(2, '0') + ':00'
  const range = (new Date(rangeDates[1]).getTime() - new Date(rangeDates[0]).getTime()) / 1000
  if (range <= 86400) {
    return `${month} ${day} ${year} ${hours}`
  } else if (range <= 86400 * 60) {
    return `${month} ${day} ${year}`
  } else {
    return `${month} ${year}`
  }
}

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
const monthKeys = [
  'datepicker-jan',
  'datepicker-feb',
  'datepicker-mar',
  'datepicker-apr',
  'datepicker-may',
  'datepicker-jun',
  'datepicker-jul',
  'datepicker-aug',
  'datepicker-sep',
  'datepicker-oct',
  'datepicker-nov',
  'datepicker-dec'
]
export function formatDateWithMonthName(d: Date): string {
  const { t } = useI18n()
  const day: number = d.getDate()
  const month = capitalize(t(monthKeys[d.getMonth()]))
  const year: number = d.getFullYear()
  return `${month} ${day}, ${year}`
}

export function formatDate(date) {
  const options: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }

  if (date) {
    return new Date(date).toLocaleDateString(undefined, options)
  }
}

export function isDevelopment() {
  return process.env.NODE_ENV === 'development'
}

export function isDevConsole() {
  return window.location.origin.includes('console-dev')
}

export function isValidDateString(date: string) {
  return date && date !== '' && date !== '0001-01-01T00:00:00Z'
}

export function getDisplayLanguage(langCode): string {
  const lang = languageCodes[langCode.slice(0, 2)]
  return lang ? lang.name : langCode
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return email && emailRegex.test(email) && email.length <= 120
}

export function shortenText(text: string, maxLength = 30): string {
  if (text && text.length > maxLength) {
    return text.slice(0, maxLength) + '...'
  }
  return text
}

export function checkName(first: string, second: string): boolean {
  return first.toLowerCase() === second.toLowerCase()
}

export function editDistance(s, t) {
  if (!s.length) return t.length
  if (!t.length) return s.length
  const arr = []
  for (let i = 0; i <= t.length; i++) {
    arr[i] = [i]
    for (let j = 1; j <= s.length; j++) {
      arr[i][j] = i === 0 ? j : Math.min(arr[i - 1][j] + 1, arr[i][j - 1] + 1, arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1))
    }
  }
  return arr[t.length][s.length]
}

export function getRouteHash() {
  return router.currentRoute.value.path
    .split('/')
    .filter((_, index) => index < Object.keys(ContentPath).length / 2) //we ignore extra elements in the path
    .join('')
}

export function getRandomColor(onlyBright = false) {
  const red = Math.floor(Math.random() * 256 + (onlyBright ? 128 : 0))
  const green = Math.floor(Math.random() * 256 + (onlyBright ? 128 : 0))
  const blue = Math.floor(Math.random() * 256 + (onlyBright ? 128 : 0))
  const color = `rgb(${red}, ${green}, ${blue})`
  store.commit('user/setUserColor', color)
  return color
}

export function getRandomPastelColor(): string {
  const randomIndex = Math.floor(Math.random() * PASTEL_COLORS.length)
  const color = PASTEL_COLORS[randomIndex].rgb
  store.commit('user/setUserColor', color)
  return color
}

export function formatText(text: string, max: number) {
  if (text.length <= max) {
    return text
  } else {
    const firstRow = text.substring(0, max)
    return `${firstRow}..`
  }
}

export function formatText2Lines(text: string, max: number) {
  if (text.length <= max) {
    return text
  } else {
    const firstRow = text.substring(0, max)
    const secondRow = text.substring(max, max + max)
    return `${firstRow}\n${secondRow}..`
  }
}

export function removeEmptyItems(body: unknown, excludedArray: string[] = []) {
  for (const [key, value] of Object.entries(body)) {
    if (
      !excludedArray.includes(key) && // do not remove item if it is in the excluded list
      ((Array.isArray(value) && (value === undefined || value.length == 0)) || value === null || value === 'undefined' || value === '')
    ) {
      delete body[key]
    }
  }
}

// This method will return json contains only the key/values that have been changed between new and old jsons

export function buildRequestBody(origObj, newObj, primaryIdKey = undefined) {
  /* eslint-disable */
  const diff = {}

  for (const key in origObj) {
    if (origObj.hasOwnProperty(key)) {
      if (!newObj.hasOwnProperty(key)) {
      } else if (typeof origObj[key] === 'object' && typeof newObj[key] === 'object') {
        const nestedDiff = buildRequestBody(origObj[key], newObj[key], primaryIdKey)
        if (Object.keys(nestedDiff).length > 0) {
          diff[key] = newObj[key]
        }
      } else if (origObj[key] !== newObj[key]) {
        diff[key] = newObj[key]
      }
    }
  }

  // Iterate over the keys in obj2 to find missing keys
  for (const key in newObj) {
    if (newObj.hasOwnProperty(key) && !origObj.hasOwnProperty(key)) {
      diff[key] = newObj[key]
    }
  }

  if (primaryIdKey) {
    diff[primaryIdKey] = newObj[primaryIdKey]
  }
  return diff
  /* eslint-enable */
}

export function validateURL(url, urls) {
  const pattern =
    /^(?:(?:(?:https):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i

  if (pattern.test(url)) {
    return !(urls.value != undefined && urls.value.some((u) => u.url === url))
  }

  return false
}

export function isValidURL(url: string): boolean {
  //eslint-disable-next-line
  const pattern = /(^ftp|^http|^https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  if (pattern.test(url)) {
    return true
  } else {
    return false
  }
}

export function cleanUpRoles(roles, inverse = false) {
  const newPermissions = JSON.parse(JSON.stringify(roles))
  const owners = newPermissions.filter((r) => {
    return r.name.includes('owner') && r.selected
  })
  owners.forEach((owner) => {
    const type = owner.name.substring(0, owner.name.indexOf('_'))
    newPermissions.forEach((role) => {
      if (!role.name.includes('owner') && role.name.includes(type)) {
        role.selected = inverse
      }
    })
  })
  return newPermissions
}

export function convertRolesToNumbers(roles): number[] {
  let newPermissions = roles.filter((r) => {
    return r.selected
  })
  newPermissions = newPermissions.map((r) => {
    return r.value
  })
  return newPermissions
}

export function isAgencyOwner(): boolean {
  return store.state.user.roles[0]?.name === UserRolesNames.AgencyOwner
}

export function isContentAccounting(): boolean {
  return store.getters['user/isRole']('content_accounting')
}

export function isPublisherAccounting(): boolean {
  return store.getters['user/isRole'](UserRolesNames.PublisherAccounting)
}

export function isPublisher(): boolean {
  return store.getters['user/isRole'](UserRolesNames.PublisherOwner)
}

export function isPublisherEditor(): boolean {
  return store.getters['user/isRole'](UserRolesNames.PublisherEditor)
}

export function isContentOwner(): boolean {
  return store.getters['user/isRole'](UserRolesNames.ContentOwner)
}

export function isContentEditor(): boolean {
  return store.getters['user/isRole'](UserRolesNames.ContentEditor)
}

export function isRole(role): boolean {
  return store.getters['user/isRole'](role)
}

export function hasRoles(roles): boolean {
  if (!Array.isArray(roles)) {
    return false
  }
  return roles.every((role) => isRole(role))
}

export function containsRole(role: string): boolean {
  return !!store.state.user.roles.find((userRole) => {
    return userRole.name === role
  })
}

export function isOwnerAccount(): boolean {
  return containsRole(UserRolesNames.PublisherOwner) || containsRole(UserRolesNames.ContentOwner) || containsRole(UserRolesNames.AgencyOwner)
}

export function hasOnlyContentEditingRoles(): boolean {
  // Get the user roles from the store
  const userRoles = store.state.user.roles
  // Extract role names from userRoles
  const userRoleNames = userRoles.map((role) => role.name)
  const hasContentOwner = userRoleNames.includes(UserRolesNames.ContentOwner)
  const hasContentEditor = userRoleNames.includes(UserRolesNames.ContentEditor)
  const hasPublisherOwner = userRoleNames.includes(UserRolesNames.PublisherOwner)
  const hasPublisherEditor = userRoleNames.includes(UserRolesNames.PublisherEditor)

  return (hasContentOwner || hasContentEditor) && !hasPublisherOwner && !hasPublisherEditor
}

export function sortFolderArrayByaAzZ(arrayToSort: Folder[]) {
  return arrayToSort.sort(function (a, b) {
    const nameA = a.name.toUpperCase() // ignore upper and lowercase
    const nameB = b.name.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    // names must be equal
    return 0
  })
}

export function formatDuration(durationInMs): string {
  const durationInSeconds = Math.floor(durationInMs / 1000)
  const hours = Math.floor(durationInSeconds / 3600)
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
  const seconds = durationInSeconds % 60
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = seconds.toString().padStart(2, '0')
  if (hours > 0) {
    const formattedHours = hours.toString().padStart(2, '0')
    return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds
  }
  return formattedMinutes + ':' + formattedSeconds
}

export function getDatesRangeStringForApi(date: Date) {
  return format(date, "yyyy-MM-dd'T'HH:mm")
}

// Helper function to get a cookie by name
export function getCookie(name) {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    // Check if the cookie name matches
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1)
    }
  }
  return null
}

// Helper function to set a cookie
export function setCookie(name, value) {
  document.cookie = `${name}=${value}; path=/; expires=`
}

export function deleteCookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

export function getPlayerCDNDomain(): string {
  return import.meta.env.VITE_APP_PLAYER_TAG_CDN_DOMAIN
}

export function timeSince(lastLoginDate: Date) {
  const now = new Date()
  const lastLogin = new Date(lastLoginDate)
  const timeDifference = now.getTime() - lastLogin.getTime()
  const minutes = Math.floor(timeDifference / (1000 * 60))
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  let ago: string
  let period: string
  if (years > 0) {
    ago = years > 1 ? i18n.global.t('years-ago') : i18n.global.t('year-ago')
    period = `${years} ${ago}`
  } else if (months > 0) {
    ago = months > 1 ? i18n.global.t('months-ago') : i18n.global.t('month-ago')
    period = `${months} ${ago}`
  } else if (days > 0) {
    ago = days > 1 ? i18n.global.t('days-ago') : i18n.global.t('day-ago')
    period = `${days} ${ago}`
  } else if (hours > 0) {
    ago = hours > 1 ? i18n.global.t('hours-ago') : i18n.global.t('hour-ago')
    period = `${hours} ${ago}`
  } else {
    ago = minutes > 1 ? i18n.global.t('minutes-ago') : i18n.global.t('minutes-ago')
    period = `${minutes} ${ago}`
  }
  return period
}

export async function checkReportsStatus() {
  let message = ''
  let onState = null

  try {
    const data = await apiService.report.checkReportStatus()

    if (data.error_message !== null && data.error_message !== '') {
      message = data.error_message
      onState = true
    }
  } catch (error) {
    // Handle errors from apiService.report.checkReportStatus() if needed
    console.error(error)
  }

  return { onState: onState, message: message }
}

export function getDateRanges(range: DateRangeNames) {
  const today = endOfDay(new Date())
  let outDates = []
  switch (range) {
    case DateRangeNames.Today:
      outDates = [startOfDay(new Date()), endOfDay(new Date())]
      break
    case DateRangeNames.Yesterday:
      outDates = [startOfYesterday(), endOfYesterday()]
      break
    case DateRangeNames.Last7days:
      outDates = [startOfDay(add(new Date(), { days: -7 })), endOfYesterday()]
      break
    case DateRangeNames.Last30days:
      outDates = [startOfDay(add(new Date(), { days: -30 })), endOfYesterday()]
      break
    case DateRangeNames.Thismonth:
      outDates = [startOfMonth(new Date()), endOfDay(new Date())]
      break
    case DateRangeNames.Lastmonth:
      outDates = [startOfDay(startOfMonth(setDate(today, 0))), setDate(today, 0)]
      break
    default:
      outDates = [new Date(today.getFullYear() - 1, 2, 9), new Date(today.getFullYear() - 1, 2, 30)]
      break
  }
  return [
    ...outDates.slice(0, 2).map((d) => {
      return format(d, "yyyy-MM-dd'T'HH:mm")
    }),
    range
  ]
}

export function filterArrayByIds(array, keys) {
  return array.filter((item) => keys.includes(item.id))
}

export function updateTitleLanguage(metaTitle) {
  if (metaTitle) {
    const title = i18n.global.t('document-title-' + metaTitle)
    document.title = title ? 'Truvid | ' + title.charAt(0).toUpperCase() + title.slice(1) : 'Truvid'
  } else {
    document.title = 'Truvid'
  }
}

function isKeyEqual(inputKeyCode: number, targetKeyCode: number): boolean {
  return inputKeyCode === targetKeyCode
}

export function isEnter(keyCode: number): boolean {
  return isKeyEqual(keyCode, KeyMap.ENTER)
}

export function isEsc(keyCode: number): boolean {
  return isKeyEqual(keyCode, KeyMap.ESC)
}

export function pl2pl(playlist: PlaylistInput): Playlist {
  const pl: Playlist = {
    playlist_id: playlist.playlist_id,
    organization_id: playlist.organization_id,
    playlist_name: playlist.playlist_name,
    create_date: playlist.create_date,
    status: playlist.status,
    videos_list: JSON.parse(playlist.videos_list),
    videos_amount: playlist.videos_amount,
    auto_updated: playlist.auto_updated,
    is_random_order: playlist.is_random_order,
    auto_updated_max_duration: playlist.auto_updated_max_duration,
    videos: playlist.videos,
    categories: playlist.categories,
    description: playlist.description,
    tags: playlist.tags
  }
  return pl
}

export function isVideoThumbnailSupported(videoURL: string): boolean {
  let fileExtension = ''
  if (videoURL && videoURL.includes('.')) {
    fileExtension = videoURL.split('.').pop()?.toLocaleLowerCase() || ''
  }

  switch (fileExtension) {
    case VIDEO_EXTENSIONS.AVI:
    case VIDEO_EXTENSIONS.MPEG:
      return false
    default:
      return true
  }
}

export function getFileNameWithoutExtension(fileName: string): string {
  if (typeof fileName !== 'string') {
    return ''
  }
  const lastDotIndex = fileName.lastIndexOf('.')
  if (lastDotIndex === -1) {
    return fileName
  }
  return fileName.substring(0, lastDotIndex)
}

export function isDefaultGeneralFolder(folderName: string): boolean {
  return folderName === TRUVID_GENERAL_FOLDER
}

export function isErroredAPIResponseStatus(res: CustomAxiosError): boolean {
  return res?.response?.status && res.response?.status !== HTTP_STATUS_OK
}

export const NewUploadVideo = (file: File): UploadVideo => ({
  file,
  id: '',
  source_url: '',
  title: getFileNameWithoutExtension(file.name),
  loadingState: LoadingState.initial,
  description: '',
  uploadProgresss: 0,
  localId: uid(),
  status: VideoStatus.Request,
  thumbnail_url: '',
  pricing_model: 'rev_share',
  exposure: 'public',
  guid: '',
  clickthrough_urls: [],
  language_id: 9,
  folder: null,
  categories: [],
  controller: undefined,
  thumbnailsLoading: true,
  clickthroughUrls: ''
})

const AddVideo: WelcomeCard = {
  imgSrc: IconUploadVideo,
  title: 'dashboard-upload',
  subtitle: 'dashboard-new-video',
  path: '/videos/videos/add'
}
const ImportStream: WelcomeCard = {
  imgSrc: IconImportStream,
  title: 'dashboard-import-from',
  subtitle: 'dashboard-import-stream',
  path: '/videos/videos/addstream'
}
const CreatePackage: WelcomeCard = {
  imgSrc: IconCreatePackage,
  title: 'dashboard-create',
  subtitle: 'dashboard-new-package',
  path: '/packages/packages/add'
}
const CreateWidget: WelcomeCard = {
  imgSrc: IconCreateWidget,
  title: 'dashboard-create',
  subtitle: 'dashboard-new-widget',
  path: '/widgets/createwidgetpicker'
}
const CreatePlaylist: WelcomeCard = {
  imgSrc: IconCreatePlaylist,
  title: 'dashboard-create',
  subtitle: 'dashboard-new-playlist',
  path: '/library/createplaylist?new=true'
}
const ReviewReports: WelcomeCard = {
  imgSrc: IconReviewReports,
  title: 'dashboard-analyze',
  subtitle: 'dashboard-reports',
  path: '/reports'
}
const CreateChannel: WelcomeCard = {
  imgSrc: IconCreateChannel,
  title: 'content-channel-preview',
  subtitle: 'dashboard-new-channel',
  path: '/videos/channel/preview'
}
const AddDomain: WelcomeCard = {
  imgSrc: IconCreateAccount,
  title: 'dashboard-create',
  subtitle: 'reports-column-domain',
  path: '/inventory/publisher-domains/add'
}
const CreateAccount: WelcomeCard = {
  imgSrc: IconCreateAccount,
  title: 'dashboard-create',
  subtitle: 'dashboard-new-account',
  path: '/accounts/add'
}
export const ReviewKnowledge: WelcomeCard = {
  imgSrc: IconReviewKnowledge,
  title: 'dashboard-explore',
  subtitle: 'dashboard-knowledge',
  path: '/explore-knowledge'
}
const GetHelp: WelcomeCard = {
  imgSrc: IconGetHelp,
  title: 'dashboard-get-help',
  subtitle: 'dashboard-support',
  path: '/get-help'
}

export function getDictionaryKey(): string {
  return store.state.user.roles
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => item.name)
    .join('_')
}

export function getDashboardWelcomeCards() {
  const welcomeCardDictionary = {}

  welcomeCardDictionary[UserRolesNames.ContentAccounting + '_' + UserRolesNames.ContentEditor] = [AddVideo, CreatePackage, ImportStream, ReviewReports]

  welcomeCardDictionary[UserRolesNames.ContentEditor + '_' + UserRolesNames.PublisherEditor] = [AddVideo, CreatePackage, CreateWidget, CreatePlaylist]

  welcomeCardDictionary[UserRolesNames.ContentEditor + '_' + UserRolesNames.PublisherAccounting] = [AddVideo, CreatePackage, ImportStream, CreateChannel]

  welcomeCardDictionary[UserRolesNames.ContentEditor + '_' + UserRolesNames.PublisherOwner] = [AddVideo, CreatePackage, CreateWidget, CreatePlaylist]

  welcomeCardDictionary[UserRolesNames.ContentAccounting + '_' + UserRolesNames.PublisherAccounting] = [ReviewReports]

  welcomeCardDictionary[UserRolesNames.ContentAccounting + ' ' + UserRolesNames.PublisherEditor] = [ReviewReports, AddDomain, CreateWidget, CreatePlaylist]

  welcomeCardDictionary[UserRolesNames.ContentAccounting + ' ' + UserRolesNames.PublisherOwner] = [ReviewReports, AddDomain, CreateWidget, CreatePlaylist]

  welcomeCardDictionary[UserRolesNames.ContentOwner + '_' + UserRolesNames.PublisherEditor] = [AddVideo, CreatePackage, CreateWidget, CreatePlaylist]

  welcomeCardDictionary[UserRolesNames.ContentOwner + '_' + UserRolesNames.PublisherAccounting] = [AddVideo, CreatePackage, ImportStream, CreateChannel]

  welcomeCardDictionary[UserRolesNames.ContentOwner + '_' + UserRolesNames.PublisherOwner] = [AddVideo, CreatePackage, CreateWidget, CreatePlaylist]

  welcomeCardDictionary[UserRolesNames.PublisherAccounting + '_' + UserRolesNames.PublisherEditor] = [ReviewReports, AddDomain, CreateWidget, CreatePlaylist]

  welcomeCardDictionary[UserRolesNames.PublisherAccounting] = [ReviewReports]

  welcomeCardDictionary[UserRolesNames.ContentAccounting] = [ReviewReports]

  welcomeCardDictionary[UserRolesNames.PublisherEditor] = [ReviewReports, AddDomain, CreateWidget, CreatePlaylist]

  welcomeCardDictionary[UserRolesNames.ContentEditor] = [AddVideo, CreatePackage, ImportStream, CreateChannel]

  welcomeCardDictionary[UserRolesNames.PublisherOwner] = [ReviewReports, AddDomain, CreateWidget, CreatePlaylist]

  welcomeCardDictionary[UserRolesNames.ContentOwner] = [AddVideo, CreatePackage, ImportStream, CreateChannel]

  welcomeCardDictionary[UserRolesNames.AgencyOwner] = [ReviewReports, ReviewKnowledge, CreateAccount, GetHelp]
  return welcomeCardDictionary
}

export const keyValueIfNotNull = (key, value) => {
  if (value) {
    return { [key]: value }
  }
  return {}
}

export function removeNullOrUndefined(jsonObject) {
  if (jsonObject) {
    for (const key in jsonObject) {
      if (jsonObject[key] == null) {
        delete jsonObject[key]
      }
    }
  }
  return jsonObject
}

const parser = new UAParser()
const parserResults = parser.getResult()

export function isSafari(): boolean {
  return parserResults.browser.name === 'Safari'
}

export function broadcastChannelSupport(): boolean {
  if (parserResults.browser.name !== 'Safari') {
    return true
  } else {
    return compare(parserResults.browser.version, '15.4.0', '>=')
  }
}

export function isSafariVersionOlderThanV16(): boolean {
  const version = parserResults.browser.version
  return isSafari() && compare(version, '16', '<=')
}

export function getISOCode(countryName: string) {
  if (countryName && countryName.length > 0) {
    return countryCode.getAlpha2Code(countryName, 'en')?.toLocaleLowerCase()
  }
  return NOT_AVAILABLE
}

export function isSubRoute(newRoute: RouteLocationNormalizedLoaded, oldRoute: RouteLocationNormalizedLoaded): boolean {
  const routes = router.getRoutes()
  const parentRoute = routes?.find((route) => {
    return route.name === oldRoute.name
  })

  if (!parentRoute) {
    return false
  }
  const newRouteIndex = parentRoute.children?.findIndex((nr) => {
    nr.name === newRoute.name
  })
  const oldRouteIndex = parentRoute.children?.findIndex((or) => {
    or.name === oldRoute.name
  })
  return newRouteIndex === oldRouteIndex
}

export function deleteCookiesWithPrefix(prefix) {
  // Get all cookies as a single string
  const cookies = document.cookie.split(';')
  // Iterate over each cookie
  for (let i = 0; i < cookies.length; i++) {
    // Get the cookie name (everything before the first '=' character)
    const cookie = cookies[i].trim()
    const cookieName = cookie.split('=')[0]
    // Check if the cookie name starts with the specified prefix
    if (cookieName.startsWith(prefix)) {
      // Delete the cookie by setting its expiry date to a past date
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
    }
  }
}

export function getGMTOffset(timezone: string): string {
  try {
    const now = new Date()
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      hour12: false,
      timeZoneName: 'short'
    })
    const parts = formatter.formatToParts(now)
    const offsetPart = parts.find((part) => part.type === 'timeZoneName')
    if (offsetPart) {
      const offset = offsetPart.value
      const match = offset.match(/(GMT)([+-]\d+)/i)
      if (match) {
        const hours = match[2]
        return `GMT${hours}`
      }
    }
    return offsetPart ? offsetPart.value.toUpperCase() : 'INVALID TIMEZONE'
  } catch (error) {
    return 'ERROR'
  }
}

export function isTimeZoneItem(rawTz: string): string | null {
  const matchedZone = TRUVID_TIME_ZONES.find((tz) => rawTz.startsWith(tz.name))
  return matchedZone ? matchedZone.name : null
}

export function migrationRequired() {
  if (store.state.user.user.console_init_org_wizard_step > TRUVID_REGISTRATION_STEPS.REGISTRATION_STEP_1 && store.state.user.user.show_create_organization) {
    return true
  }
  return false
}

export function openModalCustomConfirmation(props: ModalProps, modalType: ModalType) {
  store.commit('modal/openModal', {
    componentContent: markRaw(CustomConfirmation),
    props,
    modalType
  })
}

export function extractStoreDomainData(pattern: string, key: string): string {
  if (!pattern || !key) {
    return pattern
  }
  const domainObject = JSON.parse(pattern)
  if (key === 'id') {
    return domainObject.id
  } else if (key === 'domain') {
    return domainObject.domain
  } else {
    return pattern
  }
}
export function isRouteHasCachedViewType() {
  const routeName = getRouteName()
  return Object.values(ResizeHandleTypeRoutes).includes(routeName as ResizeHandleTypeRoutes)
}

export function getRouteName(): string {
  return router.currentRoute.value.name as string
}
