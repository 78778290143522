import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const pairedUrlsCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'url',
      title: 'domain',
      width: 35,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 1,
      priority: 1
    },
    {
      name: 'create_date',
      title: 'create-date',
      width: 15,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 2,
      priority: 2
    },
    {
      name: 'actions',
      title: '',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 0,
      priority: 0
    }
  ]
})

export default pairedUrlsCols
