import store from '@/store'
import i18n from '@/i18n'
import { pl2pl } from '@/services/utils'
import type { Playlist, PlaylistInput, Playlists, PlaylistsFilters, PlaylistsInput } from '@/services/playlist/types'
import api from '@/services/api'
import { HTTP_STATUS_OK } from '@/services/constants'
import axios from 'axios'

const getPlaylists = async (filters: PlaylistsFilters): Promise<Playlists> => {
  const a = await api.get<PlaylistsInput>('/playlists', { params: filters })
  const playlists: Playlists = { playlists: [], total: a.data.total }
  if (a.data.playlists) {
    a.data.playlists.forEach((pl) => {
      playlists.playlists.push(pl2pl(pl))
    })
  } else {
    a.data.playlists = []
  }
  return playlists
}
const getPlaylist = async (playlist_id: number): Promise<Playlist> => {
  try {
    const a = await api.get<PlaylistInput>('/playlist', { params: { playlist_id } })
    return pl2pl(a.data)
  } catch (error) {
    return Promise.reject(error)
  }
}
const postPlaylist = async (playlist: Playlist): Promise<{ playlist_id: number }> => {
  try {
    const response = await api.post<{ playlist_id: number }>('/playlist', playlist)
    // todo: after removing congratulation popups - to add message for creating playlist
    return response.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return { playlist_id: -1 }
  }
}
const postPlaylists = async (playlists: Playlist[]): Promise<boolean> => {
  const requests = []
  playlists.forEach((pl) => {
    pl.videos_list = JSON.parse(JSON.stringify(pl.videos_list))
    requests.push(api.post<Playlist>('/playlist', pl))
  })

  axios
    .all(requests)
    .then(
      axios.spread(() => {
        return true
      })
    )
    .catch(() => {
      console.log()
    })
  return true
}
const putPlaylist = async (playlist: Playlist): Promise<boolean> => {
  const link = '"' + playlist?.playlist_name + '"'
  store.dispatch('messages/addMessage', {
    message: i18n.global.t('playlist-edit-message', {
      link
    }),
    type: 'success'
  })
  const a = await api.put<Playlist>('/playlist', { ...playlist })
  return a.status === HTTP_STATUS_OK
}
const putPlaylists = async (playlists: Playlist[]): Promise<boolean> => {
  const requests = []
  playlists.forEach((pl) => {
    pl.videos_list = JSON.parse(JSON.stringify(pl.videos_list))
    requests.push(api.put<Playlist>('/playlist', pl))
  })

  await axios
    .all(requests)
    .then(
      axios.spread(() => {
        store.dispatch('messages/addMessage', {
          message: i18n.global.t('playlists-updated', { count: playlists.length, name: playlists.length > 0 ? playlists[0].playlist_name : '' }),
          type: 'success'
        })
        return true
      })
    )
    .catch(() => {
      console.log()
    })
  return true
}
const deletePlaylist = async (id: number): Promise<boolean> => {
  await api.delete<Playlist>(`/playlist/${id}`)
  return true
}

export default {
  getPlaylists,
  getPlaylist,
  postPlaylist,
  postPlaylists,
  putPlaylist,
  putPlaylists,
  deletePlaylist
}
