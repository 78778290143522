import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { DataViewType } from '@/interfaces'
import { useViewType } from '@/composables'
import { isRouteHasCachedViewType } from '@/services/utils'

export enum ResizeHandleTypeRoutes {
  Videos = 'videos',
  Folders = 'folders',
  ViewFolder = 'view-folder',
  Playlists = 'playlists',
  MyPackages = 'packages-packages',
  OfferedPackages = 'offered-packages'
}

export function useViewportResizeHandleType(breakpoint = 992) {
  const viewportWidth = ref(window.innerWidth)
  const { viewType } = useViewType()
  const viewTypeBasedOnResize = ref<DataViewType>(DataViewType.List)
  const setDataViewTypeBasedOnViewport = (): void => {
    viewportWidth.value = window.innerWidth
    if (viewportWidth.value <= breakpoint) {
      viewTypeBasedOnResize.value = DataViewType.Grid
    } else {
      viewTypeBasedOnResize.value = isRouteHasCachedViewType() ? viewType.value : DataViewType.List
    }
  }

  onMounted(() => {
    window.addEventListener('resize', setDataViewTypeBasedOnViewport)
    setDataViewTypeBasedOnViewport()
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', setDataViewTypeBasedOnViewport)
  })

  watch(
    () => viewType.value,
    () => {
      viewTypeBasedOnResize.value = viewType.value
    }
  )

  return {
    viewTypeBasedOnResize
  }
}
