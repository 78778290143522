<script setup lang="ts">
import { ListColumnVue } from './'
import { ListColumn, SortOrder } from '@/interfaces'
import { onMounted, onBeforeUnmount, computed, ref } from 'vue'
import { columnsGridTemplate, breakpointDataViewType } from '@/services/layoutUtils'

const props = defineProps<{
  columns: ListColumn[]
  sortBy?: string
  sortOrder?: SortOrder
}>()
const emit = defineEmits(['sort'])
const viewportWidth = ref(window.innerWidth)

function handleSort(column: ListColumn) {
  emit('sort', {
    sortBy: column.name,
    sortOrder: props.sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending
  })
}

onMounted(() => {
  window.addEventListener('resize', updateViewportWidth)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateViewportWidth)
})

const updateViewportWidth = () => {
  viewportWidth.value = window.innerWidth
}
const columnsToDisplay = computed(() => {
  return (columns) => {
    const breakpoints = breakpointDataViewType()
    const currentBreakpoint = getCurrentBreakpoint()
    let maxColumns = columns.length - 1 // Default to all columns if no breakpoints are defined

    if (breakpoints && breakpoints[currentBreakpoint]) {
      maxColumns = breakpoints[currentBreakpoint] >= columns.length ? columns.length : breakpoints[currentBreakpoint] || columns.length - 1
    }

    // Filter out and sort columns with defined positions
    const visibleColumns = columns.filter((col) => col && col?.position !== undefined).sort((a, b) => a.position - b.position)
    // Filter out columns with priority 1 and 0
    const priorityOneColumns = visibleColumns.filter((col) => col?.priority === 1)
    const priorityZeroColumns = visibleColumns.filter((col) => col?.priority === 0)
    let otherColumns = []
    if (breakpoints[currentBreakpoint] >= columns.length) {
      otherColumns = visibleColumns.filter((col) => col?.priority !== 1 && col?.priority !== 0).sort((a, b) => a.position - b.position)
    } else {
      otherColumns = visibleColumns.filter((col) => col?.priority !== 1 && col?.priority !== 0).sort((a, b) => a.priority - b.priority)
    }

    let adjustedColumns = []
    if (getCurrentBreakpoint() === 'md' || getCurrentBreakpoint() === 'sm' || getCurrentBreakpoint() === 'xs') {
      // Initialize adjustedColumns with priority 1 columns followed by other columns and priority 0 columns
      adjustedColumns = [...priorityOneColumns]
    } else {
      adjustedColumns = [...priorityOneColumns, ...otherColumns, ...priorityZeroColumns]

      // Add columns with priority higher than 1 if space permits
      for (let priority = 2; priority <= Math.max(...visibleColumns.map((col) => col?.priority)); priority++) {
        const priorityColumns = visibleColumns.filter((col) => col?.priority === priority)
        if (adjustedColumns.length + priorityColumns.length <= maxColumns) {
          adjustedColumns = [...adjustedColumns, ...priorityColumns]
        }
      }
    }

    const finalColumns = adjustedColumns
      .slice(0, maxColumns - 1)
      .sort((a, b) => a.position - b.position)
      .concat(priorityZeroColumns)

    return finalColumns
  }
})
const getColumnPadding = (alignment) => {
  switch (alignment) {
    case 'left':
      return '1rem 1rem 1rem 0rem'
    case 'center':
      return '1rem'
    case 'right':
      return '1rem 0rem 1rem 4rem'
    default:
      return '1rem'
  }
}
// Function to get the current breakpoint based on viewport width
const getCurrentBreakpoint = () => {
  if (viewportWidth.value >= 1900) {
    return 'hd'
  } else if (viewportWidth.value >= 1600) {
    return 'xxxl'
  } else if (viewportWidth.value >= 1400) {
    return 'xxl'
  } else if (viewportWidth.value >= 1200) {
    return 'xl'
  } else if (viewportWidth.value >= 992) {
    return 'lg'
  } else if (viewportWidth.value >= 768) {
    return 'md'
  } else if (viewportWidth.value >= 576) {
    return 'sm'
  } else {
    return 'xs'
  }
}
</script>

<template>
  <div class="columns-names" :style="`grid-template-columns: ${columnsGridTemplate(columnsToDisplay(props.columns))};`">
    <ListColumnVue
      v-for="column in columnsToDisplay(props.columns)"
      :key="column?.name"
      :column="column"
      :tooltip="column?.tooltip"
      :sort-by="props.sortBy"
      :sort-order="props.sortOrder"
      :style="{ padding: getColumnPadding(column?.alignment) }"
      @sort="() => handleSort(column)"
    />
  </div>
</template>

<style scoped lang="scss">
.columns-names {
  display: grid;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #4c4c66;
  margin-bottom: 0;
  border-bottom: 1px solid var(--title-3, #dadbe8);
  height: min-content;

  div {
    margin: 0 !important;
    padding: 1rem;
  }

  @media (max-width: $xxl) {
    .hidden-column {
      display: none;
    }
  }
}
</style>
