<template>
  <div v-show="props.column?.hideByCondition ? props.column?.hideByCondition() : true" class="column-header">
    <div :class="`${props.column?.sortable ? 'pointer' : ''}`" @click="props.column?.sortable ? handleSort() : () => {}">
      {{ props.column?.title.length > 0 ? $t(props.column?.title) : '' }}
    </div>
    <TextIconTooltip v-if="props.column?.tooltip" class="flex" :tooltip="props.column?.tooltip" />
    <div v-if="props.column?.sortable" :class="`arrows ${props.column?.sortable ? 'pointer' : ''}`" @click="props.column?.sortable ? handleSort() : () => {}">
      <img :class="{ active: props.sortOrder === SortOrder.Ascending && props.sortBy === props.column?.name }" :src="IconChevronUp" alt="">
      <img :class="{ active: props.sortOrder === SortOrder.Descending && props.sortBy === props.column?.name }" :src="IconChevronDown" alt="">
    </div>
  </div>
</template>

<script setup lang="ts">
import { ListColumn, SortOrder } from '@/interfaces'
import IconChevronUp from '@/assets/common/chevron-up.svg'
import IconChevronDown from '@/assets/common/chevron-down.svg'
import TextIconTooltip from '@/components/common/tooltips/TextIconTooltip.vue'

interface ListColumnParams {
  column: ListColumn
  sortBy?: string
  sortOrder: SortOrder
}
const props = defineProps<ListColumnParams>()
const emit = defineEmits(['sort'])

function handleSort() {
  emit('sort')
}
</script>

<style scoped lang="scss">
.column-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  * {
    white-space: nowrap;
    overflow: hidden;
  }
}

.arrows {
  margin-left: 12px;
  display: flex;
  flex-direction: column;

  & > img {
    opacity: 0.3;
    margin: 2px 0;
  }
}

.active {
  opacity: 1 !important;
}
</style>
