<template>
  <div ref="outside" class="datepicker-tag" @click.self="openDatePicker">
    <div class="tag" @click.self="openDatePicker">
      {{ datesRangeString }}
      <img src="@/assets/datepicker/downarrow.svg" alt="down arrow" :class="{ opened: datepickerDropdown }">
    </div>

    <div v-show="datepickerDropdown">
      <DatePickerWrapper @close="datepickerDropdown = false">
        <div class="datepicker" :class="{ left: props?.align === 'left', right: props?.align === 'right', 'with-scrollbar': windowWidth < MOBILE_WIDTH }">
          <div ref="datepicker" class="datepicker-container">
            <div class="datapicker-options">
              <p class="option" :class="{ selected: selectedDateRange === DateRangeNames.Today }" @click="(event) => getDateRange(event, DateRangeNames.Today)">
                {{ $t('reports-datefilter-today') }}
              </p>
              <p class="option" :class="{ selected: selectedDateRange === DateRangeNames.Yesterday }" @click="(event) => getDateRange(event, DateRangeNames.Yesterday)">
                {{ $t('reports-datefilter-yesterday') }}
              </p>
              <p class="option" :class="{ selected: selectedDateRange === DateRangeNames.Last7days }" @click="(event) => getDateRange(event, DateRangeNames.Last7days)">
                {{ $t('reports-datefilter-last7days') }}
              </p>
              <p class="option" :class="{ selected: selectedDateRange === DateRangeNames.Thismonth }" @click="(event) => getDateRange(event, DateRangeNames.Thismonth)">
                {{ $t('reports-datefilter-thismonth') }}
              </p>
              <p class="option" :class="{ selected: selectedDateRange === DateRangeNames.Last30days }" @click="(event) => getDateRange(event, DateRangeNames.Last30days)">
                {{ $t('reports-datefilter-last30days') }}
              </p>
              <p class="option" :class="{ selected: selectedDateRange === DateRangeNames.Lastmonth }" @click="(event) => getDateRange(event, DateRangeNames.Lastmonth)">
                {{ $t('reports-datefilter-lastmonth') }}
              </p>
              <p class="option" :class="{ selected: !selectedDateRange || selectedDateRange === DateRangeNames.Custom }" @click="() => (selectedDateRange = DateRangeNames.Custom)">
                {{ $t('reports-datefilter-customrange') }}
              </p>
            </div>
            <div class="datapicker-calendar" :class="{ notcustom: selectedDateRange !== DateRangeNames.Custom }">
              <div>
                <div id="flatpickr" class="datepicker-wrapper" />
              </div>
              <div class="line" />
              <div class="textinputs" :class="{ firstdatesmaller: errorMsg === 'datepicker-startdate-bigger' }">
                <div>
                  <div class="label">
                    <TextIconTooltip :text="$t('datepicker-customtext-from')" :tooltip="$t('datepicker-customtext-from-info')" />
                  </div>

                  <CustomInputValidation
                    ref="dateStringInputStart"
                    placeholder="dd/mm/yyyyy"
                    :input-type="CustomInputType.String"
                    :model-value="textInputStartDate"
                    :validation-object="{ isValidStringDate: true, isValidStringDateTodayOrOlder: true, isValidStringDateLaterThanMinDate: true }"
                    @update:model-value="updateStartDate"
                    @change="validate"
                  />
                  <div class="errormsg">{{ errorMsg.length > 0 ? $t(errorMsg) : '' }}</div>
                </div>
                <div>
                  <div class="label">
                    <TextIconTooltip :text="$t('datepicker-customtext-to')" :tooltip="$t('datepicker-customtext-to-info')" />
                  </div>
                  <CustomInputValidation
                    ref="dateStringInputEnd"
                    :validation-object="{ isValidStringDate: true, isValidStringDateTodayOrOlder: true, isValidStringDateLaterThanMinDate: true }"
                    placeholder="dd/mm/yyyyy"
                    :input-type="CustomInputType.String"
                    :model-value="textInputEndDate"
                    @update:model-value="updateEndDate"
                    @change="validate"
                  />
                  <button class="primary" :class="{ disabled: !validated }" @click="apply">{{ $t('apply') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DatePickerWrapper>
    </div>
  </div>
</template>

<script setup lang="ts">
import DatePickerWrapper from './DatePickerWrapper.vue'
import 'flatpickr/dist/flatpickr.css'
import flatpickr from 'flatpickr'
import { useI18n } from 'vue-i18n'
import { add, endOfDay, startOfDay } from 'date-fns'
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { english } from 'flatpickr/dist/l10n/default.js'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { Mandarin } from 'flatpickr/dist/l10n/zh.js'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { Japanese } from 'flatpickr/dist/l10n/ja.js'
import store from '@/store'
import { getDatesRangeStringForApi, getDateRanges, createDateForTimeZone, convertDateForTimeZone } from '@/services/utils'
import { watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { CustomInputType, DateRangeNames } from '@/interfaces'
import CustomInputValidation from './CustomInputValidation.vue'
import TextIconTooltip from '@/components/common/tooltips/TextIconTooltip.vue'
import { MOBILE_WIDTH, TRUVID_MIN_DATE } from '@/services/constants'

const { t } = useI18n()
const props = defineProps(['modelValue', 'valid', 'align', 'timeZone'])
const emit = defineEmits(['update:modelValue', 'change', 'validate'])
const datepickerDropdown = ref(false)
const selectedDateRange = ref(DateRangeNames.Last30days)
const datepicker = ref(null)
const flatpickrElement = ref<flatpickr.Instance | null>(null)
const outside = ref(null)
const datesRangeString = ref('')
const textInputStartDate = ref('')
const textInputEndDate = ref('')
const dateStringInputStart = ref(null)
const dateStringInputEnd = ref(null)
const windowWidth = ref(0)
function update(val: Date[]) {
  selectedDateRange.value = DateRangeNames.Custom
  textInputStartDate.value = formatTextInputDate(val[0])
  textInputEndDate.value = formatTextInputDate(val[1] ? val[1] : val[0])
  validate().then((v) => {
    validated.value = v
  })
}

function formatTextInputDate(date: string) {
  const d = new Date(date)
  return `${d.getDate().toString().padStart(2, 0)}/${(d.getMonth() + 1).toString().padStart(2, 0)}/${d.getFullYear()}`
}

async function updateEndDate(val) {
  textInputEndDate.value = val
  validated.value = await validate()
}

async function updateStartDate(val) {
  textInputStartDate.value = val
  validated.value = await validate()
  if (validated.value) {
    flatpickrElement.value.setDate([convertInputDateStringToDate(textInputStartDate.value), convertInputDateStringToDate(textInputEndDate.value, true)])
  }
}
const errorMsg = ref('')
const validated = ref(false)

async function validate() {
  if ((await dateStringInputEnd.value?.validate()) && (await dateStringInputStart.value?.validate())) {
    if (compareDates(textInputStartDate.value, textInputEndDate.value)) {
      errorMsg.value = 'datepicker-startdate-bigger'
      return false
    } else {
      errorMsg.value = ''
      return true
    }
  } else {
    errorMsg.value = ''
    return false
  }
}

const compareDates = (fromDateString: string, toDateString: string) => {
  const fromDate = convertInputDateStringToDate(fromDateString)
  const toDate = convertInputDateStringToDate(toDateString, true)
  return fromDate.getTime() > toDate.getTime()
}

function convertInputDateStringToDate(dateString: string, isEndOfDay = false) {
  const d = dateString.split('/')
  return convertDateForTimeZone(new Date(d[2] + '-' + d[1] + '-' + d[0]), props.timeZone, isEndOfDay)
}

async function apply() {
  const valid = await validate()
  if (valid) {
    const start = convertInputDateStringToDate(textInputStartDate.value)
    const end = convertInputDateStringToDate(textInputEndDate.value, true)
    flatpickrElement.value.setDate([start, end])
    date.value = [start, end, DateRangeNames.Custom]
    validated.value = false
    datepickerDropdown.value = false
  }
}

watch(
  () => props.modelValue,
  () => {
    if (flatpickrElement.value && new Date(props.modelValue[1]).getTime() > 0 && new Date(props.modelValue[0]).getTime() > 0) {
      flatpickrElement.value.setDate([new Date(props.modelValue[0]), new Date(props.modelValue[1])])
    }
    datesRangeString.value = getDatesRangeString()
  }
)

const date = computed({
  get: () => {
    return [new Date(props.modelValue[0]), new Date(props.modelValue[1]), props.modelValue[2]]
  },
  set: (val) => {
    val[0] = startOfDay(val[0])
    val[1] = endOfDay(val[1])
    selectedDateRange.value = val[2]
    emit('update:modelValue', [getDatesRangeStringForApi(val[0]), getDatesRangeStringForApi(val[1]), val[2]])
    emit('validate')
  }
})

function getLocale(val: string) {
  switch (val) {
    case 'en':
      return english
    case 'pt':
      return Portuguese
    case 'zh':
      return Mandarin
    case 'es':
      return Spanish
    case 'ja':
      return Japanese
    default:
      return english
  }
}

function getDatesRangeString() {
  const d = date.value
  if (d.length === 3) {
    let dateString = ''
    dateString += formatDateString(d[0])
    dateString += ' - ' + formatDateString(d[1])
    return dateString
  }
  if (d.length === 2) {
    let dateString = ''
    dateString += formatDateString(d[0])
    dateString += ' - ' + formatDateString(d[1])
    return dateString
  }

  if (d.length === 1) {
    let dateString = ''
    dateString += formatDateString(d[0])
    dateString += ' - ' + formatDateString(d[0])
    return dateString
  }

  return '-'
}

function openDatePicker(e) {
  e.stopPropagation()
  datepickerDropdown.value = !datepickerDropdown.value
  errorMsg.value = ''
  validated.value = false
  window.requestAnimationFrame(() => {
    textInputStartDate.value = formatTextInputDate(date.value[0])
    textInputEndDate.value = formatTextInputDate(date.value[1])
    const today = new Date()
    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
    const tomorrowDateString = `${tomorrow.getFullYear()}-${(tomorrow.getMonth() + 1).toString().padStart(2, '0')}-${tomorrow.getDate().toString().padStart(2, '0')}`
    const flatpickrElementNode = document.querySelector('#flatpickr') as HTMLElement
    flatpickrElement.value = flatpickr(flatpickrElementNode, {
      inline: true,
      mode: 'range',
      dateFormat: 'Y-m-d',
      formatDate: (date) => createDateForTimeZone(date, props.timeZone),
      maxDate: add(new Date(), { days: 1 }),
      minDate: TRUVID_MIN_DATE,
      locale: getLocale(store.getters['cache/getPageLanguage']()),
      disable: [{ from: tomorrowDateString, to: '4023-07-13' }],
      onChange: [update]
    })
    if (!props.modelValue) return
    if (new Date(props.modelValue[1]).getTime() > 0 && new Date(props.modelValue[0]).getTime() > 0) {
      flatpickrElement.value.setDate(date.value.slice(0, 2))
      selectedDateRange.value = date.value[2]
    } else {
      getDateRange(null, DateRangeNames.Last30days)
    }
  })
}

function formatDateString(date: Date): string {
  let da = new Date(date).toDateString().split(' ')
  da[0] = t(`datepicker-${da[0].toLocaleLowerCase()}`)
  da[1] = t(`datepicker-${da[1].toLocaleLowerCase()}`)
  if (store.getters['cache/getPageLanguage']() === 'zh') {
    da = [da[3], da[1], da[2]]
  }
  return `${da[1]} ${da[2]}/${da[3]}`
}

function getDateRange(e: Event, range: DateRangeNames) {
  e?.stopPropagation()

  if (range !== DateRangeNames.Custom) {
    const newDates = getDateRanges(range)
    flatpickrElement.value.setDate(newDates.slice(0, 2))
    date.value = [new Date(flatpickrElement.value.selectedDates[0]), new Date(flatpickrElement.value.selectedDates[1]), range]
    datepickerDropdown.value = false
  } else {
    date.value = [date.value[0], date.value[1], range]
  }
}

onMounted(() => {
  windowWidth.value = window.innerWidth
  datepickerDropdown.value = false
  //default dates on load last 30 days
  const newDates = getDateRanges(DateRangeNames.Last30days)
  date.value = [new Date(newDates[0]), new Date(newDates[1]), DateRangeNames.Last30days]

  datesRangeString.value = getDatesRangeString()
  onClickOutside(outside.value, () => (datepickerDropdown.value = false))

  window.addEventListener('resize', () => {
    if (window.innerWidth >= MOBILE_WIDTH && windowWidth.value < MOBILE_WIDTH) {
      datepickerDropdown.value = false
    }
    if (window.innerWidth < MOBILE_WIDTH && windowWidth.value >= MOBILE_WIDTH) {
      datepickerDropdown.value = false
    }

    windowWidth.value = window.innerWidth
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', () => {
    //datepickerDropdown.value = false
    windowWidth.value = window.innerWidth
  })
})
</script>

<style scoped lang="scss">
.datepicker-wrapper {
  display: none;
  pointer-events: none;
  opacity: 0.2;
}

.datepicker {
  pointer-events: all;

  @media (min-width: calc(#{$mobile_width} + 1px)) {
    position: absolute;
    width: fit-content;
    height: 480px;
    bottom: -12px;
    transform: translateY(100%);
    right: 0;
    z-index: 1000;

    &.right {
      left: -20%;
    }
  }

  .datepicker-container {
    pointer-events: auto;
    display: flex;
    border-radius: 10px;
    height: 100%;

    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    height: 500px;
    @media (max-width: $mobile_width) {
      width: 100vw;
      height: calc(100% - 30px);
      grid-template-columns: 1fr;
      grid-template-rows: 130px 0 300px;
      background-color: transparent;

      box-shadow: none;
      flex-flow: column;
      padding-bottom: 40px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    * {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
    .notcustom {
      opacity: 0.4;
      pointer-events: none;
    }
    .line {
      border-top: 1px solid #eef1f4;
      width: 78%;
      margin-left: 11%;
      margin-top: -12px;
      @media (max-width: $mobile_width) {
        margin-top: 17px;
      }
    }
    .textinputs {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      padding: 0 24px 0 24px;
      margin-top: 15px;
      color: var(--Body-text, #4c4c66);
      /* Body/B2 Med */
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      .errormsg {
        width: 20px;
        overflow: visible;
        transform: translateY(-19px);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: #ff0000;
        white-space: nowrap;
      }

      button {
        float: right;
        margin: 0;
        margin-top: 20px;
      }
      .label {
        margin-bottom: 8px;
      }
    }
  }
}

.mobilebkg {
  @media (max-width: $mobile_width) {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 2;
  }
}
.option {
  margin: 4px 0;
  padding: 8px 16px 8px 16px;
  color: var(--Title-2, #7d81b2);
  /* Body/B2 Med */
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 4px;
  &.selected {
    background-color: #f2f4fa;
    cursor: default;
    position: none;
  }
  &:hover:not(.selected) {
    color: $blue;
  }
  @media (max-width: $mobile_width) {
    border-radius: 50px;
    border: 1px solid var(--Title, #9599c6);
    margin: 8px 4px;
    padding: 8px 12px;
  }
}
.datapicker-calendar {
  width: 470px;
  padding: 0 20px 0 20px;
  @media (max-width: $mobile_width) {
    width: 100%;
    margin: auto;
  }
}
.datapicker-options {
  padding: 20px 30px 20px 20px;
  height: 100%;
  width: fit-content;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: $mobile_width) {
    border-right: 2px solid #eef1f4;
  }

  @media (max-width: $mobile_width) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px;
    max-width: 540px;
  }

  @media (max-width: $mobile_width) {
    // display: none;
    grid-template-columns: 120px 120px;
    margin: 10px auto 0 auto;
    z-index: 1;
  }
}

.datepicker-tag {
  min-height: 35px;
  width: fit-content;
  max-width: 100%;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  position: relative;
  /* identical to box height */

  letter-spacing: 0.2px;

  /* dark gray */

  color: var(--Title, #4c4c66);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 12px;

  @media (max-width: $sm) {
    min-width: 1px;
    font-weight: 500;
  }

  .tag {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-transform: capitalize;

    img {
      pointer-events: none;
      margin-left: 8px;
      &.opened {
        transform: rotate(180deg);
      }

      &:first-child {
        margin-right: 6px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fly-enter-active,
.fly-leave-active {
  transition: all 0.5s ease;
}

.fly-enter-from,
.fly-leave-to {
  transform: translateY(100%);
}
</style>

<style lang="scss">
.flatpickr-calendar {
  box-shadow: none;
  margin: 12px auto 24px auto;

  @media (max-width: $mobile_width) {
    width: fit-content;
    margin: 0 calc(50% - 154px);
    margin-top: 16px;
  }
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $primary-color;
}

.flatpickr-monthDropdown-months {
  color: red;
}

.firstdatesmaller {
  .input-container {
    border: 1px solid $error;
  }
  .input-container:focus-within:not(:has(.is-disabled, .disabled)),
  textarea:focus:not(:has(.is-disabled, .disabled)) {
    background-color: $white;
    border: 1px solid $inputBackground;
    outline: 0;
    outline: 0;
    box-shadow: $boxShadowDefinition;
    color: $titleText;

    box-shadow: $boxShadowDefinition $error;
  }
}
</style>
