/* eslint-disable @typescript-eslint/no-explicit-any */

import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import type { Cache } from './cache.module'
import type { Dashboard } from './dashboard.module'
import type { Folders } from './folders.module'
import type { Library } from './library.module'
import type { Messages } from './messages.module'
import type { Packages } from './packages.module'
import type { Playlists } from './playlists.module'
import type { Reports } from './reports.module'
import type { Settings } from './settings.module'
import type { User } from './user.module'
import type { Widget } from './widget.module'
import type { Widgets } from './widgets.module'
import type { IModalState } from './custom-modal.module'
import { TodoList } from '@/interfaces'
import { cache } from './cache.module'
import { dashboard } from './dashboard.module'
import { folders } from './folders.module'
import { library } from './library.module'
import { messages } from './messages.module'
import { packages } from './packages.module'
import { playlists } from './playlists.module'
import { reports } from './reports.module'
import { settings } from './settings.module'
import { user } from './user.module'
import { widget } from './widget.module'
import { widgets } from './widgets.module'
import { todoList } from './todolist.module'
import { modal } from './custom-modal.module'

export interface State {
  user: User
  library: Library
  playlists: Playlists
  settings: Settings
  widgets: Widgets
  packages: Packages
  dashboard: Dashboard
  messages: Messages
  cache: Cache
  widget: Widget
  folders: Folders
  isAuthenticated: boolean
  redirectRoute: string
  dataViewType: string
  reports: Reports
  innerVideoEmbededModel: string
  dataViewCurrentPage: string
  totalPages: number
  todoList: TodoList
  modal: IModalState
}

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
const store = createStore({
  modules: {
    user,
    library,
    playlists,
    settings,
    widgets,
    packages,
    dashboard,
    messages,
    folders,
    widget,
    cache,
    reports,
    todoList,
    modal
  },
  plugins: [vuexLocal.plugin],
  getters: {
    getInnerVideoEmbededModel: (state: any) => () => {
      return state.innerVideoEmbededModel
    }
  },
  mutations: {
    setAuthenticated(state: any, res: boolean) {
      state.isAuthenticated = res
    },
    setRedirectRoute(state, res: string) {
      state.redirectRoute = res
    },
    setDataViewType(state: any, res: string) {
      state.dataViewType = res
    },
    setInnerVideoEmbededModel(state, res: string) {
      state.innerVideoEmbededModel = res
    },
    setDataViewCurrentPage(state, res: string) {
      state.dataViewCurrentPage = res
    },
    setTotalPages(state, total: number) {
      state.totalPages = total
    }
  }
})

export default store
