import authService from '@/services/auth.service'
import { UserRolesNames } from '@/services/organization-user/constants'
import { deleteCookie, getCookie, migrationRequired, setCookie, updateTitleLanguage } from '@/services/utils'
import store from '@/store'
import { checkPermission } from '@/store/permissions'
import { createRouter, createWebHistory } from 'vue-router'
import {
  accountsRoutes,
  dashboardRoutes,
  domainsRoutes,
  externalRoutes,
  inventoryRoutes,
  libraryRoutes,
  packagesRoutes,
  playlistsRoutes,
  settingsRoutes,
  videosRoutes,
  widgetsRoutes
} from './routes'
import type { RouteRecordRaw } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue'),
    meta: {
      title: 'register'
    }
  },
  {
    path: '/ui',
    name: 'ui',
    component: () => import('@/views/UI_elements.vue')
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/Maintenance.vue')
  },
  {
    path: '/signup-complete',
    name: 'signupcomplete',
    component: () => import('@/views/SignupComplete.vue'),
    meta: {
      title: 'sign up complete'
    }
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/views/Layout.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      title: 'login'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/password/ForgotPassword.vue'),
    meta: {
      title: 'reset-password'
    }
  },
  {
    path: '/reset-password',
    name: 'resetpassword',
    component: () => import('@/views/password/ResetPassword.vue'),
    meta: {
      title: 'reset-password'
    }
  },
  {
    path: '/org-auth',
    name: 'org-auth',
    component: {
      template: '<div />'
    },
    beforeEnter: async (to, from, next) => {
      const { organizationId, userId, rs } = to.query as { organizationId: string; userId: string; rs: string }
      if (organizationId && userId && rs) {
        await authService.loginFromAuth(Number(organizationId), Number(userId), rs)
      }
      // todo redirect to 404 if token expired or not available
      next({ name: 'home' })
    }
  },
  {
    path: '/',
    name: 'home',
    redirect: () => {
      if (migrationRequired() && !store.state.user.isLoggingFromAuth) {
        return '/login'
      }
      if (!store.state.isAuthenticated) {
        return '/login'
      }
      return '/dashboard/'
    },
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/DashboardPage.vue'),
        meta: {
          title: 'dashboard'
        },
        redirect: () => {
          if (checkPermission('publisher_dashboard')) {
            return '/dashboard/publisher-dashboard'
          } else if (checkPermission('content_dashboard')) {
            return '/dashboard/content-dashboard'
          } else if (checkPermission('agency_dashboard')) {
            return '/dashboard/agency-dashboard'
          } else if (store.getters['user/isRole'](UserRolesNames.ContentEditor)) {
            return '/videos/videos'
          } else if (store.getters['user/isRole'](UserRolesNames.PublisherEditor)) {
            return '/library'
          } else if ([UserRolesNames.ContentAccounting, UserRolesNames.PublisherAccounting].some((r) => store.getters['user/isRole'](r))) {
            return '/reports'
          } else {
            return '/login'
          }
        },
        beforeEnter: () => {
          heap.identify(store.state.user.user.first_name + ' ' + store.state.user.user.last_name)
          heap.addUserProperties({email: store.state.user.user.email, organization: store.state.user.user.organization_id})
        },
        children: dashboardRoutes
      },
      {
        path: 'inventory',
        name: 'inventory',
        redirect: () => {
          if (!checkPermission('inventory_my_domains') && checkPermission('inventory_excluded_domains')) {
            return '/inventory/excluded-domains'
          } else {
            return '/inventory/publisher-domains'
          }
        },
        component: () => import('@/views/inventory/InventoryPage.vue'),
        children: inventoryRoutes,
        meta: {
          title: 'inventory'
        },
        beforeEnter: (to, from, next) => checkPermission('inventory', next)
      },
      {
        path: 'videos',
        name: 'my-videos',
        redirect: '/videos/videos',
        component: () => import('@/views/content/MyVideosPage.vue'),
        children: videosRoutes,
        meta: {
          title: 'my-videos'
        },
        beforeEnter: (to, from, next) => checkPermission('videos', next)
      },
      {
        path: 'library',
        name: 'library',
        component: () => import('@/views/content/LibraryPage.vue'),
        meta: {
          title: 'library'
        },
        children: libraryRoutes
        // beforeEnter: (to, from, next) => checkPermission(to.path, next),
      },
      {
        path: 'packages',
        name: 'packages',
        redirect: () => {
          let rp = ''
          if (!checkPermission('my_packages_packages') && checkPermission('packages_offered_packages')) {
            rp = '/packages/offered-packages'
          } else if (checkPermission('my_packages_packages')) {
            rp = '/packages/packages'
          }
          return rp
        },
        component: () => import('@/views/content/PackagesPage.vue'),
        children: packagesRoutes,
        meta: {
          title: 'packages'
        }
      },
      {
        path: 'playlists',
        name: 'playlists',
        props: true,
        component: () => import('@/views/content/PlaylistPage.vue'),
        meta: {
          title: 'playlists'
        },
        children: playlistsRoutes
      },
      {
        path: 'widgets',
        name: 'widgets',
        component: () => import('@/views/widget/WidgetPage.vue'),
        beforeEnter: (to, from, next) => checkPermission(to.path, next),
        meta: {
          title: 'widgets'
        },
        children: widgetsRoutes
      },

      {
        path: 'reports',
        name: 'reports',
        component: () => import('@/views/reports/ReportsPage.vue'),
        beforeEnter: (to, from, next) => checkPermission('reports', next),
        meta: {
          title: 'reports'
        }
      },

      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/settings/SettingsPage.vue'),
        meta: {
          title: 'settings'
        },
        children: settingsRoutes
      },

      {
        path: 'domains-inventory',
        name: 'domains-inventory',
        component: () => import('@/views/domains/AgencyDomainsPage.vue'),
        meta: {
          title: 'inventory'
        },
        children: domainsRoutes,
        beforeEnter: (to, from, next) => checkPermission(to.path, next)
      },
      {
        path: 'accounts',
        name: 'accounts',
        component: () => import('@/views/accounts/AccountsPage.vue'),
        meta: {
          title: 'accounts'
        },
        children: accountsRoutes,
        beforeEnter: (to, from, next) => checkPermission(to.path, next)
      }
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export const nonAuthRoutes = externalRoutes.map((r) => r.path)
// export const nonAuthRoutes = ['login', 'register', 'password', 'ui', 'layout']

router.beforeEach(async (to) => {
  if (import.meta.env.VITE_APP_MAINTENANCE_MODE === 'true') {
    if (to.name !== 'maintenance') {
      return { name: 'maintenance' }
    } else {
      return
    }
  }

  if (to.name === 'org-auth') {
    deleteCookie('lastLoginTime')
    await authService.logout(false)
    localStorage.removeItem('vuex')
    return
  }

  updateTitleLanguage(to.meta.title)

  if (!store.state.isAuthenticated && window.history.state.back === null && !nonAuthRoutes.some((r) => to.fullPath.includes(r))) {
    store.commit('setRedirectRoute', window.history.state.current)
  }
  // If the route is the login page, don't perform authentication check
  if (to.name === 'login') {
    return
  }

  if (!store.state.isAuthenticated && !nonAuthRoutes.some((r) => to.fullPath.includes(r))) {
    return { name: 'login' }
  }

  // Check the expiration date of the cookie
  const lastLoginTime = getCookie('lastLoginTime')
  if (lastLoginTime) {
    const twoDaysAgo = new Date()
    const twoDays = 2
    twoDaysAgo.setDate(twoDaysAgo.getDate() - twoDays)

    if (new Date(lastLoginTime) <= twoDaysAgo) {
      // Logout the user if the cookie is 2 days old
      deleteCookie('lastLoginTime')
      await authService.logout()
      return
    }
  } else {
    // If the cookie doesn't exist and the user is authenticated, create a new one
    if (store.state.isAuthenticated) {
      const now = new Date()
      setCookie('lastLoginTime', now.toUTCString())
    }
  }
})

export default router
