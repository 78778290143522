import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import type { Video } from '@/services/videos/types'
import { Ref } from 'vue'
export interface AxiosErrorResponse {
  message: string
  code?: string
  status?: number
  details?: string
  error?: {
    description?: string
  }
}

export interface CustomAxiosError extends AxiosError {
  config: AxiosRequestConfig
  request?: any
  response?: AxiosResponse<AxiosErrorResponse>
}

export type WelcomeCard = {
  imgSrc: string
  title: string
  subtitle: string
  path: string
}

export interface LibraryFilter {
  duration: number[]
  startDate: string
  endDate: string
  categories: number[]
  creatorId: number
  pricing: string
  uploadDate: string
  sortBy: string | SortByOptions
  sortOrder: string | SortOrder
  languages: number[]
  search: string
  page_number: number
  is_private: boolean
  ownership: boolean
  show_packages: false
  show_public: false
}

export interface Category {
  id: number
  name?: string
}

export interface VideoCategory {
  category: Category
  videos: Video[]
}

export interface Language {
  id: number
  language: string
  '2letter': string
  '3letter': string
}

export interface Creators {
  creators: {
    map(arg0: (l: any) => boolean): unknown
    organization_id: number
    name: string
  }
}

export interface ReportColumn {
  column_name: string
  orderable: boolean
  title: string
  is_default: boolean
  is_selected: boolean
  searchable: boolean
}

export interface ReportColumns {
  columns: ReportColumn[]
}

export enum EmbedTypes {
  None,
  Code,
  Url
}

export enum VideoStatus {
  Init = 'init',
  Request = 'request',
  Uploaded = 'uploaded',
  Register = 'register',
  Uploading = 'uploading',
  Fail = 'fail'
}

export enum VideoDetailScreenTypes {
  AddToPlaylist = 'addtoplaylist',
  Embeded = 'embeded',
  PairedUrls = 'pairedurls'
}
export interface TargetingUrl {
  id: number
  organization_id: number
  url: string
  video_id: string
  create_date: string
}

export const DashboardGraphParameters = {
  agencyActivity: ['server_calls_timeline', 'revenue_timeline'],
  agencyQuality: ['ad_viewability_timeline', 'fill_rate_timeline'],
  contentActivity: ['content_views_timeline', 'revenue_timeline'],
  publisherActivity: ['revenue_timeline', 'server_calls_timeline'],
  publisherQuality: ['viewability_imps_timeline', 'viewability_fill_timeline']
}

export enum DashboardPublisherActivityMetrics {
  revenue = 'revenue', // (for publisher_activity_top)
  cpm = 'vcpm', // (for publisher_activity_top)
  fill = 'fill', // (for publisher_activity_top))
  server_calls = 'server_calls' // (for publisher_activity_top)
  // content_views = 'content_views', // (for publisher_quality_top)
}

export enum DashboardAgencyActivityMetrics {
  revenue = 'revenue', // (for agency_activity_top)
  vcpm = 'vcpm', // (for agency_activity_top)
  fill_rate = 'fill_rate', // (for agency_activity_top)
  server_calls = 'server_calls' // (for agency_activity_top)
}

export enum DashboardContentActivityMetrics {
  revenue = 'revenue', // (for content_activity_top)
  cpm = 'cpm', // (for content_activity_top)
  fill_rate = 'fill_rate', // (for content_activity_top)
  content_views = 'content_views' // (for content_activity_top)
}

export enum DashboardContentActivityInfoMetrics {
  revenue = 'revenue', // (for content_activity_info)
  cpm = 'cpm', // (for content_activity_info)
  fill_rate = 'fill_rate', // (for content_activity_info)
  content_views = 'content_views' // (for content_activity_info)
}

export interface DashboardPublisherActivity {
  server_calls: number
  server_calls_percentage_change: number
  revenue: number
  revenue_percentage_change: number
  viewable_impressions: number
  vcpm: number
  vcpm_percentage_change: number
  viewable_impressions_percentage_change: number
  viewable_fill: number
  viewable_fill_percentage_change: number
  revenue_timeline: any
  server_calls_timeline: any
}

export interface DashboardPublisherQuality {
  server_calls: number
  server_calls_percentage_change: number
  revenue: number
  revenue_percentage_change: number
  viewable_impressions: number
  vcpm: number
  vcpm_percentage_change: number
  viewable_impression_percentage_change: number
  viewable_fill: number
  viewable_fill_percentage_change: number
  viewability_imps_timeline: any
  viewability_fill_timeline: any
}

export interface DashboardPublisherQualityInfo {
  server_calls_desktop_percentage: number
  server_calls_mobile_percentage: number
  cpm_desktop: number
  cpm_mobile: number
}

export interface DashboardPublisherActivityTop {
  countries: any
}

export interface DashboardPublisherQualityTop {
  videos: any
}

export interface DashboardAgencyAccount {
  organization_name: string
  ecpm: number
  viewable_impressions: number
  impressions: number
  commision: number
  revenue: number
  revenue_percentage_change: number
}

export interface DashboardAgencyAccounts {
  organizations: DashboardAgencyAccount[]
}

export interface DashboardAgencyActivity {
  server_calls: number
  server_calls_percentage_change: number
  fill_rate: number
  fill_rate_percentage_change: number
  vcpm: number
  vcpm_percentage_change: number
  revenue: number
  revenue_percentage_change: number
  agency_fee: number
  agency_fee_percentage_change: number
  revenue_timeline: any
  server_calls_timeline: any

  //viewable_impressions: number
  //viewable_impression_percentage_change: number
  //viewable_fill: number
  //viewable_fill_percentage_change: number
}

export interface DashboardAgencyActivityTop {
  countries: any
}

export interface DashboardAgencyQuality {
  server_calls: number
  server_calls_percentage_change: number
  revenue: number
  revenue_percentage_change: number
  view_rate: number
  view_rate_percentage_change: number
  vcpm: number
  vcpm_percentage_change: number
  ad_viewability_timeline: any
  fill_rate_timeline: any
}

export interface DashboardAgencyQualityInfo {
  server_calls_desktop_percentage: number
  server_calls_mobile_percentage: number
  cpm_desktop: number
  cpm_mobile: number
}

export interface DashboardContentActivity {
  revenue: number
  revenue_percentage_change: number
  content_views: number
  content_views_percentage_change: number
  cpm: number
  cpm_percentage_change: number
  ads: number
  ads_percentage_change: number
  fill_rate: number
  fill_rate_percentage_change: number
  revenue_timeline: any
  content_views_timeline: any
}

export interface DashboardContentActivityInfo {
  content_views_desktop_percentage: number
  content_views_mobile_percentage: number
  domains: any
}

export interface DashboardContentActivityTop {
  countries: any
}

export enum LoadingState {
  idle = 'idle',
  initial = 'initial',
  loading = 'loading',
  more = 'more',
  success = 'success',
  error = 'error'
}

export enum Stacks {
  ModalOverlay = 'modal_overlay',
  SideContainers = 'side_containers'
}

export enum DataType {
  Folder = 'folders',
  Package = 'packages',
  Video = 'videos',
  Playlist = 'playlists',
  Widget = 'widgets',
  Account = 'accounts',
  PackageFolderComponent = 'packageFolders',
  ExcludedDomains = 'excludedDomains',
  OfferedPackages = 'proposals',
  MyDomains = 'domains',
  Users = 'users',
  PairedUrls = 'contentTargeting',
  ContentSources = 'streams',
  AgencyAccounts = 'organizations',
  Channel = 'personalChannel'
}

export const DataTypeApiMapping = {
  folders: 'folder',
  packages: 'contentPackage',
  videos: 'videos',
  playlists: 'playlist',
  widgets: 'widget',
  accounts: 'organizationUser',
  packageFolders: 'folder',
  excludedDomains: 'content',
  proposals: 'contentPackage',
  domains: 'domain',
  users: 'organizationUser',
  contentTargeting: 'content',
  streams: 'videos',
  organizations: 'organization',
  personalChannel: 'channel'
}

export interface ListColumnTitle {
  title: string
  orderBy?: string
  sortable?: boolean
  sorted?: boolean
  sortBy?: string
}

export enum ContentTabs {
  Videos = 'videos',
  Folders = 'folders',
  Packages = 'packages'
}

export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc'
}

export enum SortByOptions {
  UploadDate = 'upload_date',
  Title = 'title',
  DurationInMs = 'duration_in_ms'
}

export type SortBy = SortByOptions | ''

export type FilterField = 'duration' | 'category' | 'pricing_model' | 'language'

export type SortOption = {
  label?: string
  sortBy: SortBy
  sortOrder: SortOrder
}

export type FilterOption = {
  label: string
  filterName: FilterField
}

export enum ListColumnAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

export type ListColumn = {
  [x: string]: any
  name: string
  title: string
  width: string | number
  alignment?: ListColumnAlignment
  sortable?: boolean
  offset?: number
  tooltip?: string
  hideByCondition?: () => boolean
}

export enum DataViewType {
  Grid = 'grid',
  List = 'list'
}

export enum ReportType {
  console_publisher_truvid_report = 'console_publisher_truvid_report',
  console_publisher_content_report = 'console_publisher_content_report',
  console_content_content_report = 'console_content_content_report',
  console_accounts_account_report = 'console_accounts_account_report'
}

export enum DataViewCardSize {
  XXL = 400,
  XL = 350,
  L = 300,
  M = 250,
  S = 200,
  XS = 150,
  XXS = 100
}

export interface VideoComponentOptions {
  selected?: boolean
  bare?: boolean
  removeEmbedButton?: boolean
  removeAddButton?: boolean
  withBottom?: boolean
  imageIdx?: number
  threeDots?: boolean
  withTitleEvents?: boolean
  hoverAnimation?: boolean
  selectOnly?: boolean
  interactive?: boolean
  customLink?: string
  onClick?: () => void
  detailsLink?: string
  defaultCursor?: boolean
  playVideo?: boolean
  showTime?: boolean
  showOwnership?: boolean
  showTopText?: boolean
}

export enum CRUD {
  Add = 'add',
  Edit = 'edit',
  Delete = 'delete',
  View = 'view',
  Duplicate = 'duplicate',
  Deactivate = 'deactivate',
  Activate = 'activate',
  Confirm = 'confirm',
  ConfirmClose = 'confirmClose',
  CloseCreate = 'closeCreate',
  Unpair = 'unpair',
  ConfirmAccept = 'accept',
  ConfirmDecline = 'decline'
}

export enum ContentPath {
  Domain = 0,
  Route = 1,
  DataType = 2,
  CRUD = 3,
  Param = 4
}

export interface DataActions {
  refreshData: () => void
  searchData?: (e: Ref<string>) => void
  clearSearch?: () => void
  resetToDefaultPageAndGetData?: () => void
}

export interface ContentDataRouterConfig {
  setIgnoreDataRouter: (val: boolean) => void
}

export enum CustomInputType {
  Number = 'number',
  String = 'text',
  Multiselect = 'multiselect',
  RadioGroup = 'radio',
  CheckBoxGroup = 'checkbox',
  Password = 'password',
  Textarea = 'textarea',
  Date = 'date'
  // ...
}

export enum InputIconActionType {
  // log the current input value
  Log = 'log',
  // clear the current input value
  Clear = 'clear'
  // ...
}

export interface ValidationParams {
  required?: boolean
  minLen?: number
  maxLen?: number
  minVal?: number
  step?: number
  maxVal?: number
  alphaNum?: boolean
  numeric?: boolean
  integer?: boolean
  email?: boolean
  url?: boolean
  domain?: boolean
  patternValidation?: RegExp
  checkboxN?: number
  multiN?: number
  isInUse?: boolean
  sameAs?: any
  notSameAs?: any
  customBackendValidationError?: boolean
  isValidStringDateTodayOrOlder?: boolean
  isValidStringDate?: boolean
  validationFunc?: (...args: any[]) => any
  asyncValidation?: (...args: any[]) => any
  // ...
}

export interface ValidationObject {
  value: number | string | boolean | object | null
  valid: boolean
  id: string
}

export interface CustomErrorMessages {
  required?: string
  minLen?: string
  maxLen?: string
  minVal?: string
  maxVal?: string
  alphaNum?: string
  numeric?: string
  integer?: string
  email?: string
  isInUse?: string
  url?: string
  domain?: string
  patternValidation?: string
  checkboxN?: string
  multiN?: string
  sameAs?: string
  notSameAs?: string
  asyncValidation?: string
  date?: string
  // ...
}
export interface RadioGroupInput {
  id: string
  label: string
  tooltip?: string
  description?: string
  value: string | number | boolean | symbol | object
}

export interface CheckboxGroupInput {
  id: string
  label: string
  selected: boolean | null
}

export enum InputLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
  Mobile = 'mobile'
}

export enum Devices {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Both = 'both'
}

export interface NoDataBtn {
  txt: string
  route: string
}

export enum BaseRoutes {
  dashboard = 'dashboard',
  library = 'library',
  playlists = 'playlists',
  widgets = 'widgets',
  reports = 'reports',
  notifications = 'notifications',
  settings = 'settings',
  content = 'content',
  accounts = 'accounts',
  inventory = 'inventory',
  'domains-inventory' = 'domains-inventory',
  packages = 'packages',
  videos = 'videos'
}

export interface SideMenuItem {
  route: string
  permissions?: string[]
  device: Devices
  subItems?: SideMenuItem[]
  buttonOnly?: boolean
  showSubItems?: boolean
}

export interface ErrorMessage {
  error_message: string
}

export interface LeadRequest {
  id: string
  first_name: string
  last_name: string
  country_id: number
  language_id: number
  organization_name: string
  organization_website: string
  is_completed: boolean
}

export enum DashboardType {
  None = 0,
  PublisherDashboard = 1,
  ContentDashboard = 2,
  AgencyDashboard = 3
}

export const DataViewBreakpoints = {
  ExcludedDomains: { xs: 1, lg: 2, xl: 5, xxl: 6, xxxl: 10, hd: 15 },
  OfferedPackages: { xs: 1, lg: 2, xl: 3, xxl: 4, xxxl: 5, hd: 15 },
  Video: { xs: 2, lg: 2, xl: 3, xxl: 5, xxxl: 6, hd: 15 },
  Folder: { xs: 2, lg: 3, xl: 3, xxl: 6, xxxl: 10, hd: 15 },
  Package: { xs: 2, lg: 3, xl: 5, xxl: 6, xxxl: 10, hd: 15 },
  Playlist: { xs: 2, lg: 3, xl: 4, xxl: 5, xxxl: 10, hd: 15 },
  Widget: { xs: 2, lg: 3, xl: 5, xxl: 6, xxxl: 10, hd: 15 },
  MyDomains: { xs: 2, lg: 2, xl: 3, xxl: 4, xxxl: 10, hd: 15 },
  Users: { xs: 1, lg: 2, xl: 3, xxl: 4, xxxl: 5, hd: 15 },
  ContentSources: { xs: 2, lg: 3, xl: 5, xxl: 6, xxxl: 8, hd: 15 },
  AgencyAccounts: { xs: 1, lg: 3, xl: 4, xxl: 5, xxxl: 10, hd: 15 },
  PairedUrls: { xs: 2, lg: 3, xl: 5, xxl: 6, xxxl: 10, hd: 15 },
  Proposals: { xs: 2, lg: 2, xl: 3, xxl: 6, xxxl: 10, hd: 15 }
}

export type TextWrap = 'wrap' | 'nowrap' | 'balance' | 'pretty' | 'stable'

export enum DateRangeNames {
  Today = 'today',
  Yesterday = 'yesterday',
  Last7days = 'last7days',
  Last30days = 'last30days',
  Thismonth = 'thismonth',
  Lastmonth = 'lastmonth',
  Custom = 'custom'
}

export interface TodoList {
  accountCreated: boolean
  domainsVisited: boolean
  copyADStxt: boolean
  checkADStxt: boolean
  billingInfoAvailable: boolean
  reportsVisited: boolean
  accountLogin: boolean
  domainCreated: boolean
  playlistCreated: boolean
  widgetCreated: boolean
  embedCodeCopied: boolean
  videoUploaded: boolean
  sourceImported: boolean
  profileUpdated: boolean
  packageCreated: boolean
  packageDistributed: boolean
}

export interface CustomModalProps {
  text?: string
  textSecondary?: string
  buttonConfirm?: string
  buttonCancel?: string
  buttonConfirmText?: string
  buttonCancelText?: string
  noHeader?: boolean
  noFooter?: boolean
  height?: string
  width?: string
  margin?: string
  padding?: string
  maxWidth?: string
  maxHeight?: string
  preventCloseOnBackdropClick?: boolean
  action?: CRUD
  type?: DataType
}

export interface ModalOpenParams {
  content: object
  header?: object
  footer?: object
  props?: object
}

export interface CustomConfirmationResponse {
  id?: string | number
  action: CRUD
  type: DataType
  field: string
  inputValue: string | number | object
  value: boolean
  forceQuit?: boolean
}

export interface CustomConfirmationActionEvent {
  actionType: string
  payload: CustomConfirmationResponse
}

export interface CustomConfirmationActionHandlers {
  confirmClose?: (params?: any) => any
  delete?: (params?: any) => any
  duplicate?: (params?: any) => any
  confirm?: (params?: any) => any
  deactivate?: (params?: any) => any
  activate?: (params?: any) => any
  add?: (params?: any) => any
  edit?: (params?: any) => any
  unpair?: (params?: any) => any
  view?: (params?: any) => any
  closeCreate?: (params?: any) => any
}
