import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const playlistCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'playlist_name',
      title: 'playlists-playlist-name',
      width: 25,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 2,
      priority: 1
    },
    {
      name: 'playlist_id',
      title: 'playlists-playlist-id',
      width: 14,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 1,
      priority: 2
    },
    {
      name: 'videos_amount',
      title: 'playlists-videos-numbers',
      width: 13,
      alignment: ListColumnAlignment.Center,
      position: 4,
      priority: 4
    },
    {
      name: 'related_tags',
      title: 'content-package-related-categories',
      width: 22,
      alignment: ListColumnAlignment.Center,
      position: 3,
      priority: 3
    },
    {
      name: 'create_date',
      title: 'create-date',
      width: 7,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 5,
      priority: 5
    },
    {
      name: 'actions',
      title: '',
      width: 1,
      alignment: ListColumnAlignment.Center,
      position: 1,
      priority: 0
    }
  ]
})

export default playlistCols
