<template>
  <div :class="{ grid: true, 'no-data': noData, 'is-loading': isLoading, 'with-scrollbar': true }">
    <LoadingSpinner v-if="isLoading" class="loading-spinner" />
    <!-- No Data -->
    <NoData v-if="!isLoading && noData && !hasNoDataSlot" :which="!!(noData && (searchVal || filters)) ? 'noSearchResults' : type" />
    <slot v-else-if="!isLoading && noData && hasNoDataSlot" name="NoData" />
    <div v-else ref="gridScrollCont" class="container">
      <ListColumns
        v-if="!isFrameView && firstDataLoaded" :columns="columns" :sort-by="sortBy || initSortBy" :sort-order="sortOrder || initSortOrder"
        @sort="handleSort"
      />
      <!-- Grid View -->
      <div v-if="isFrameView" :class="{ 'no-text-selection': true, records: true, faded: isLoading }">
        <div v-for="item in storeRecords" :key="item.id || item.video_id" class="record">
          <component :is="dataComponent" :item="item" :view-type="props.viewType" v-bind="options" />
        </div>
        <div v-for="i in 7" :key="i" class="record" style="aspect-ratio: 0" />
        <div v-if="!isLoading" v-element-visibility="onElementVisibility" />
      </div>
      <!-- List View -->
      <div v-else>
        <div v-for="item in storeRecords" :key="item.id || item.video_id">
          <!-- Render components based on filtered columns -->
          <!-- Check if the current column has breakpoints defined -->
          <component
            :is="dataComponent" :key="item.id || item.video_id" :item="item" :view-type="props.viewType"
            :columns="columns"
          />

          <!-- Optional separator -->
          <div v-if="props.addSeparator" class="line" />
        </div>
        <Pagination
          v-if="!props.data && storeRecords && (storeRecords.length || Object.keys(storeRecords).length)"
          :total-items="storeTotal"
          :items-per-page="resultsPerPage"
          :current-page="currentPage"
          :total-pages="totalPages"
          :name="`${props.customName ? capitalize(props.customName) : capitalize(props.type)}`"
          @back="handleNavigation('previous')"
          @forward="handleNavigation('next')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { apiService } from '@/services'
import * as Vue from 'vue'
import { capitalize, computed, onMounted, ref, useSlots, watch } from 'vue'
import { useStore } from 'vuex'
import { ListColumns, NoData, Pagination } from '@/components/common'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { useLastRequest } from '@/composables/lastRequest'
//Models
import VideoComponent from '@/components/base/VideoComponent.vue'
import VideoInfoDataView from '@/components/content/videos/VideoInfoDataView.vue'
import FolderInfoDataView from '@/components/content/videos/folders/FolderInfoDataView.vue'
import PlaylistDataView from '@/components/content/playlist/PlaylistDataView.vue'
import WidgetDataView from '@/components/widgets/WidgetDataView.vue'
import {
  agencyAccountCols,
  contentSourcesCols,
  excludedDomainCols,
  folderCols,
  myDomainsCols,
  myDomainsColsAgency,
  offeredPackagesCols,
  packageCols,
  pairedUrlsCols,
  playlistCols,
  usersCols,
  videoCols,
  widgetCols
} from '@/data'
import ExcludedDomainsDataView from '@/components/inventory/excluded-domains/ExcludedDomainsDataView.vue'
import UsersDataView from '@/components/settings/users/UsersDataView.vue'
import MyDomainsDataView from '@/components/inventory/my-domains/MyDomainsDataView.vue'
import OfferedPackagesDataView from '@/components/content/packages/offered-packages/OfferedPackagesDataView.vue'
import MyImportsDataView from '@/components/content/videos/my-imports/MyImportsDataView.vue'
import AccountsDataView from '@/components/accounts/AccountsDataView.vue'
import PairedUrlsDataView from '@/components/settings/content/PairedUrlsDataView.vue'
import PackageInfoDataView from '@/components/content/packages/my-packages/PackageInfoDataView.vue'
import AddFolderToPackageDataView from '@/components/content/packages/my-packages/addpackage/AddFolderToPackageDataView.vue'
import type { Video, VideoFilters } from '@/services/videos/types'
import { Folder } from '@/services/folder/types'
import { Package } from '@/services/content/package/types'
import { UserRolesNames } from '@/services/organization-user/constants'
import { DataType, DataTypeApiMapping, DataViewCardSize, DataViewType, ListColumn, SortBy, SortOrder } from '@/interfaces'
import router from '@/router'
import { debounce } from '@/services/utils'
import { useRoute } from 'vue-router'
import { vElementVisibility } from '@vueuse/components'
import { useViewType } from '@/composables'
import { DEFAULT_DATA_VIEW_PAGE } from '@/services/constants'

const PER_PAGE_GRID = 20
const PER_PAGE_LIST = 8

type Model = Video | Folder | Package

interface DataViewProps {
  viewType: DataViewType
  type: DataType
  data?: Model[]
  cardSize?: DataViewCardSize
  options?: object & { filters?: object }
  customName?: string
  addSeparator?: boolean
  disableInitialFetch?: boolean
}

onMounted(async () => {
  isLoading.value = true
  await init(props.disableInitialFetch)
})

const { setViewType } = useViewType()
const props = withDefaults(defineProps<DataViewProps>(), {
  cardSize: DataViewCardSize.L,
  addSeparator: true
})
const store = useStore()
const resultsPerPage = ref(8)
const slots = useSlots()
const route = useRoute()
const sortBy = ref<string>()
const sortOrder = ref<SortOrder>()
const currentPage = ref(1)
const searchVal = ref<string>('')
const filters = ref<VideoFilters | null | undefined>(null)
const firstDataLoaded = ref(false)
const localStoreArr = ref([])
const localStoreTotal = ref(0)
const emits = defineEmits(['on-data-fetched'])

function onElementVisibility(isVisible: boolean) {
  if (isVisible) {
    fetchMoreRecords()
  }
}

const gridScrollCont = ref<HTMLElement | null>(null)
const cardSizePx = computed(() => `${props.cardSize}px`)
const dataComponent = computed<Vue.Component>(() => {
  switch (props.type) {
    case DataType.Widget:
      return WidgetDataView
    case DataType.Playlist:
      return PlaylistDataView
    case DataType.PackageFolderComponent:
      return AddFolderToPackageDataView
    case DataType.Video:
      return props.viewType === DataViewType.List ? VideoInfoDataView : VideoComponent
    case DataType.Folder:
      return FolderInfoDataView
    case DataType.Package:
      return PackageInfoDataView
    case DataType.ExcludedDomains:
      return ExcludedDomainsDataView
    case DataType.MyDomains:
      return MyDomainsDataView
    case DataType.Users:
      return UsersDataView
    case DataType.OfferedPackages:
      return OfferedPackagesDataView
    case DataType.ContentSources:
      return MyImportsDataView
    case DataType.AgencyAccounts:
      return AccountsDataView
    case DataType.PairedUrls:
      return PairedUrlsDataView
    default:
      throw new Error('No data type was passed as prop to DataView component')
  }
})
const initSortBy = computed<string>(() => {
  switch (props.type) {
    case DataType.Video:
      return 'upload_date'
    case DataType.PackageFolderComponent:
      return 'name'
    case DataType.Folder:
    case DataType.Playlist:
    case DataType.Widget:
    case DataType.MyDomains:
    case DataType.PairedUrls:
      return 'create_date'
    case DataType.Package:
    case DataType.ExcludedDomains:
    case DataType.OfferedPackages:
    case DataType.AgencyAccounts:
      return 'created_at'
    case DataType.Users:
      return 'last_login'
    case DataType.ContentSources:
      return 'created_date'

    default:
      throw new Error('No data type was passed as prop to DataView component')
  }
})
const initSortOrder = computed<SortOrder>(() => {
  switch (props.type) {
    case DataType.PackageFolderComponent:
      return SortOrder.Ascending
    default:
      return SortOrder.Descending
  }
})
const columns = computed<ListColumn[]>(() => {
  switch (props.type) {
    case DataType.ExcludedDomains:
      return excludedDomainCols.value
    case DataType.OfferedPackages:
      return offeredPackagesCols.value
    case DataType.Video:
      return videoCols.value
    case DataType.Folder:
      return folderCols.value
    case DataType.Package:
      return packageCols.value
    case DataType.Playlist:
      return playlistCols.value
    case DataType.Widget:
      return widgetCols.value
    case DataType.MyDomains:
      return store.state.user.roles[0]?.name === UserRolesNames.AgencyOwner ? myDomainsColsAgency.value : myDomainsCols.value
    case DataType.Users:
      return usersCols.value
    case DataType.ContentSources:
      return contentSourcesCols.value
    case DataType.AgencyAccounts:
      return agencyAccountCols.value
    case DataType.PairedUrls:
      return pairedUrlsCols.value
    default:
      throw new Error('No data type was passed as prop to DataView component')
  }
})
// TODO - gilad - need to check regarging packages if need to use full URL for my-pakcages
const organizationIdRequired = computed(() => {
  return (
    router.currentRoute.value.fullPath.includes('/videos/videos') ||
    router.currentRoute.value.fullPath.endsWith('/packages') ||
    router.currentRoute.value.fullPath.includes('/packages/add') ||
    router.currentRoute.value.fullPath.includes('/packages/edit') ||
    router.currentRoute.value.fullPath.includes('/videos/folders')
  )
})
const storeRecords = computed(() => (!props.data && store.state[props.type] ? store.state[props.type][props.type] : localStoreArr.value))
const isFrameView = computed(() => props.viewType === DataViewType.Grid)
const storeTotal = computed(() => (!props.data && store.state[props.type] ? store.state[props.type][`total${capitalize(props.type)}`] : localStoreTotal.value))
const noData = computed(() => !isLoading.value && !storeTotal.value)
const hasNoDataSlot = computed(() => !!slots.NoData)
const totalPages = computed(() => Math.ceil(storeTotal.value / resultsPerPage.value))
const fetchParams = computed(() => {
  return {
    ...({ ...filters.value, ...(props.options?.filters ?? {}) } ?? {}),
    results_per_page: resultsPerPage.value,
    sort_by: sortBy.value ?? initSortBy.value,
    sort_order: sortOrder.value ?? initSortOrder.value,
    page_number: currentPage.value,
    ...(searchVal.value ? { search: searchVal.value } : {}),
    organization_id: organizationIdRequired.value ? store.state.user.user.organization_id : undefined
  }
})
const { execute: fetchData, executing: isLoading } = useLastRequest<paginatedResponse>({
  request: async () => {
    resultsPerPage.value = isFrameView.value ? PER_PAGE_GRID : PER_PAGE_LIST
    return await getRecords()
  },
  onCompleted: (data) => {
    storeData(data)
    firstDataLoaded.value = true
  },
  onError: (err) => {
    console.error(err)
    firstDataLoaded.value = true
  }
})
const onChangeDebounced = debounce(async () => {
  await fetchData()
}, 250)
const setCurrentPage = (page: number) => {
  if (page < 1) {
    page = 1
  } else if (page > store.state.totalPages) {
    page = store.state.totalPages
  }
  currentPage.value = page
  store.commit('setDataViewCurrentPage', page)
}
const setDefaultPage = () => {
  setCurrentPage(DEFAULT_DATA_VIEW_PAGE)
}
const resetToDefaultPageAndGetData = async () => {
  isLoading.value = true
  setDefaultPage()
  resetData()
  await onChangeDebounced()
  await resetRouteToDefaultPage()
}
const handleNavigation = async (direction: string) => {
  if (direction === 'next') {
    currentPage.value = store.state.dataViewCurrentPage === totalPages.value ? store.state.dataViewCurrentPage : store.state.dataViewCurrentPage + 1
  } else if (direction === 'previous') {
    currentPage.value = store.state.dataViewCurrentPage === 1 ? store.state.dataViewCurrentPage : store.state.dataViewCurrentPage - 1
  }

  store.commit('setDataViewCurrentPage', currentPage.value)
  await fetchData()
  await router.push({ name: route.name, query: { ...route.query, page: currentPage.value } })
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// function refreshRow(updatedItem: any, item: any) {
//   const index = localStoreArr.value.findIndex((oldItem) => oldItem.id === item.id)
//   if (index !== -1) {
//     localStoreArr.value[index] = updatedItem
//   }
// }

function resetData() {
  firstDataLoaded.value = false
  sortBy.value = initSortBy.value
  if (store.state[props.type]) {
    store.dispatch(`${props.type}/reset`)
  } else {
    localStoreArr.value = []
    localStoreTotal.value = 0
  }
}

async function resetRouteToDefaultPage() {
  if (!isFrameView.value) {
    await router.push({ name: route.name, query: { ...route.query, page: DEFAULT_DATA_VIEW_PAGE } })
  }
}

async function handleSort(sortObj: { sortOrder: SortOrder; sortBy: SortBy }) {
  resetData()
  sortOrder.value = sortObj.sortOrder
  sortBy.value = sortObj.sortBy
  await fetchData()
}

async function getRecords() {
  if (props.type === DataType.PairedUrls) {
    const content = await apiService[DataTypeApiMapping[props.type]][`get${capitalize(props.type)}`](fetchParams.value)
    let contentTargeting = content
    if (content.total > 0) {
      const groupedItems = content.content_targeting
        .filter((item) => item.domain)
        .reduce((acc, item) => {
          const domain = item.domain
          if (!acc[domain]) {
            acc[domain] = {
              urls: [],
              domain: item
            }
          }
          acc[domain].urls.push(item)
          return acc
        }, {})
      contentTargeting = groupedItems
    } else {
      contentTargeting = {}
    }

    if (!Object.keys(contentTargeting).length) {
      return {
        content_targeting: {},
        total: 0
      }
    }

    return {
      content_targeting: Object.fromEntries(
        Object.entries(contentTargeting).slice((currentPage.value - 1) * resultsPerPage.value, (currentPage.value - 1) * resultsPerPage.value + resultsPerPage.value)
      ),
      total: Object.keys(contentTargeting).length
    }
  } else {
    return await apiService[DataTypeApiMapping[props.type]][`get${capitalize(props.type === DataType.PackageFolderComponent ? DataType.Folder : props.type)}`](fetchParams.value)
  }
}

export type paginatedResponse = object & {
  total?: number
  total_videos?: number
}

function getViewType() {
  return props.type.replace(/[A-Z]/g, (cap) => `_${cap.toLowerCase()}`)
}

function storeData(values: paginatedResponse) {
  emits('on-data-fetched', values)
  const records = values[props.type === DataType.PackageFolderComponent ? DataType.Folder : getViewType()]
  if (records) {
    if (props.type === DataType.AgencyAccounts) {
      records.filter((record) => record.is_content_owner || record.is_publisher)
    }
    const recordsToStoreByViewType = isFrameView.value ? [...storeRecords.value, ...records] : records
    store.state[props.type] ? store.commit(`${props.type}/set${capitalize(props.type)}`, recordsToStoreByViewType) : (localStoreArr.value = recordsToStoreByViewType)
    store.state[props.type] ? store.commit(`${props.type}/setTotal`, values.total) : (localStoreTotal.value = values.total ?? values.total_videos)
    const totalPages = Math.max(1, Math.ceil(props.type === DataType.Video ? values.total_videos / resultsPerPage.value : values.total / resultsPerPage.value))
    store.commit('setTotalPages', totalPages)
  }
}

async function fetchMoreRecords() {
  const totalPagesComputed = [DataType.Video, DataType.Playlist, DataType.OfferedPackages].includes(props.type) ? totalPages.value : totalPages.value
  store.commit('setTotalPages', totalPagesComputed)

  if (!isLoading.value && storeTotal.value > 0 && totalPagesComputed > currentPage.value) {
    currentPage.value += 1
    await fetchData()
  }
}

function handleScroll() {
  //temporary till we cancel old requests
  if (!isFrameView.value) return

  if (gridScrollCont.value && gridScrollCont.value.scrollHeight - gridScrollCont.value.scrollTop <= gridScrollCont.value.clientHeight + 2) {
    fetchMoreRecords()
  }
}

function searchFilterLocalData() {
  //search
  localStoreArr.value = props.data.filter((rec) => (rec as Video).title.toLowerCase().includes(searchVal.value.toLowerCase()))
  //filters
  if (filters.value) {
    localStoreArr.value = localStoreArr.value.filter((rec) => rec.duration_in_ms >= filters.value.min_duration && rec.duration_in_ms <= filters.value.max_duration)
    localStoreArr.value =
      typeof filters.value.categories === 'object' ? localStoreArr.value : localStoreArr.value.filter((rec) => rec.categories.find((cat) => cat === filters.value.categories))
    localStoreArr.value = filters.value.languageId === -1 ? localStoreArr.value : localStoreArr.value.filter((rec) => rec.languageId === filters.value.languageId)
    localStoreArr.value = !filters.value.pricing_model ? localStoreArr.value : localStoreArr.value.filter((rec) => rec.pricing_model === filters.value.pricing_model)
  }

  //total
  localStoreTotal.value = localStoreArr.value.length
}

function setSearchValue(val: string) {
  searchVal.value = val
  resetToDefaultPageAndGetData()
}

function setFilters(filtersObj: VideoFilters) {
  if (filtersObj === undefined) {
    filters.value = undefined
  } else {
    filters.value = { ...filters.value, ...filtersObj }
  }
}

function resetFilters() {
  filters.value = undefined
}

async function init(disableInitialFetch) {
  if (!disableInitialFetch) {
    if (route.query.page) {
      await onPageRoute()
    }
    if (props.data) {
      localStoreArr.value = props.data
      localStoreTotal.value = props.data.length
    } else {
      await fetchData()
      if (gridScrollCont.value) {
        gridScrollCont.value.onscroll = isFrameView.value ? handleScroll : null
      }
    }
  }
}

async function refreshData() {
  resetData()
  isLoading.value = true
  setTimeout(async () => {
    await fetchData()
  }, 1000) // TODO: check if this can be dropped.
}

async function onPageRoute() {
  const pageQuery = route.query.page
  const pageNumber = pageQuery ? parseInt(pageQuery as string) : null
  if (pageNumber) {
    setViewType(DataViewType.List)
    currentPage.value = pageNumber === 0 ? 1 : pageNumber <= store.state.totalPages ? pageNumber : store.state.totalPages
    setCurrentPage(currentPage.value)

    await router.push({
      name: route.name,
      query: { ...route.query, page: currentPage.value.toString() }
    })
  } else if (props.viewType === DataViewType.List && !pageNumber) {
    await router.push({
      name: route.name,
      query: { ...route.query, page: DEFAULT_DATA_VIEW_PAGE.toString() }
    })
  }
}

watch(
  () => store.state.dataViewType,
  (newDataViewType, oldDataViewType) => {
    if (newDataViewType !== oldDataViewType) {
      if (!route.query.page) {
        setCurrentPage(currentPage.value)
      }
    }
  }
)

watch(
  () => props.viewType,
  async (newViewType, oldViewType) => {
    if (newViewType === DataViewType.Grid) {
      await router.replace({ query: {} })
    } else if (newViewType === DataViewType.List) {
      if (!route.query.page) {
        await router.replace({ query: { page: '1' } })
      }
    }
    if (newViewType !== oldViewType) {
      resetData()
      await init(props.disableInitialFetch)
    }
  }
)

watch(
  () => gridScrollCont.value,
  async () => {
    if (gridScrollCont.value) {
      gridScrollCont.value.onscroll = isFrameView.value ? handleScroll : null
    }
  }
)

watch(
  () => props.type,
  async (newVal, oldVal) => {
    resetData()
    if (props.viewType !== DataViewType.List) {
      await router.push({ name: route.name, query: {} })
    } else if (newVal !== oldVal && !route.query.page) {
      setDefaultPage()
    }
    store.commit('setDataViewType', props.type)
  },
  { immediate: true }
)

watch(filters, async (filters) => {
  if (props.data) {
    searchFilterLocalData()
  } else if (filters) {
    await resetToDefaultPageAndGetData()
  }
})

defineExpose({
  refreshData,
  setSortValue: handleSort,
  setSearchValue,
  setFilters,
  resetFilters,
  init,
  isLoading: () => isLoading.value,
  noData: () => noData.value,
  resetToDefaultPageAndGetData
})
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  height: 100%;

  &.no-data,
  &.no-results,
  &.is-loading {
    min-height: 65vh;
  }
}

.no-text-selection {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 0.3rem;
}

.line {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: end;
  height: 1px;
  background-color: #dadbe8;
  width: 100%;
  bottom: 0;
}

.records {
  display: flex;
  flex-flow: row;
  gap: 32px 35px;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  margin: 40px 0 20px 0;
  padding: 0 1rem;

  .record {
    width: 100%;
    flex-basis: v-bind(cardSizePx);
    flex-grow: 1;
    border-radius: 0 0 6px 6px;
    box-sizing: border-box;
    box-shadow: none;
    aspect-ratio: 16/9;
    z-index: $recordZIndex;

    @media (max-width: $xl) {
      flex-basis: 280px;
    }

    &:hover {
      z-index: $hoveredRecordZIndex;
    }
  }
}
</style>
