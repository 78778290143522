<template>
  <div class="pagination-control">
    <div class="pages">
      {{ (props.currentPage - 1) * props.itemsPerPage + 1 }} - {{ Math.min(props.currentPage * props.itemsPerPage, props.totalItems) }} {{ $t('pagination-of') }}
      {{ props.totalItems }} {{ $t(props.name.toLowerCase()) }}
    </div>
    <div class="skip left" :class="props.currentPage === 1 ? 'disabled' : ''" @click="handleBack">
      <svg
        width="6" height="12" viewBox="0 0 6 12" fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.5 11L0.5 6L5.5 1" stroke="#4C4C66" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <div>{{ props.currentPage }} / {{ props.totalPages }}</div>
    <div class="skip right" :class="props.currentPage === props.totalPages || props.currentPage === props.totalPages + 1 ? 'disabled' : ''" @click="handleForward">
      <svg
        width="6" height="12" viewBox="0 0 6 12" fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.5 11L5.5 6L0.5 1" stroke="#4C4C66" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps(['currentPage', 'totalPages', 'name', 'totalItems', 'itemsPerPage'])
const emit = defineEmits(['forward', 'farForward', 'back', 'farBack'])

function handleForward() {
  if (!(props.currentPage === props.totalPages || props.currentPage === props.totalPages + 1)) {
    emit('forward')
  }
}

function handleBack() {
  emit('back')
}
</script>

<style scoped>
.pages {
  margin-right: 107px;
}
.skip {
  background: #f2f4fa;
  /* padding: 1px 10px;*/
  margin: 0 5px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.skip:hover {
  border-radius: 3px;
  box-shadow: 0px 0px 2px 0px rgba(65, 91, 169, 0.5);
}

.pagination-control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.disabled {
  color: #a0a0a0;
  opacity: 0.7;
  cursor: auto;
  pointer-events: none;
}
</style>
