import { CheckboxGroupInput } from '@/interfaces'
import { TRUVID_MIN_DATE } from '@/services/constants'
import isValidDomain from 'is-valid-domain'

/* DEFINE CUSTOM VALIDATORS HERE AND IMPLEMENT IN CustomInputValidation file */

const atLeastNCheckbox = (n: number) => (value: CheckboxGroupInput[]) => {
  let counter = 0
  value.forEach((el: CheckboxGroupInput) => {
    el.selected ? counter++ : null
  })
  return counter >= n
}
const atLeastNOptions = (n: number) => (value: string[]) => {
  const counter = value.length
  return counter >= n
}
const isValidDate = (value: Date[] | string[]) => {
  return !isNaN(new Date(value[0]).getTime()) && !isNaN(new Date(value[1]).getTime())
}
const isValidDomainPattern = (value: string) => {
  return isValidDomain(value, { wildcard: true })
}
const isValidStringDate = (value: string) => {
  if (!value) return false
  if (value.length < 10) return false
  const d = value.split('/')
  if (d.length !== 3) return false
  return !isNaN(Date.parse(d[2] + '-' + d[1] + '-' + d[0]))
}
const isValidStringDateTodayOrOlder = (value: string) => {
  if (isValidStringDate(value)) {
    const d = value.split('/')
    const dd = new Date(d[2] + '-' + d[1] + '-' + d[0])
    return dd.getTime() <= new Date().getTime()
  }
  return true
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const notSameAs = (updatedValue: any) => (value: any) => {
  return updatedValue.toLowerCase() !== value.toLowerCase()
}
const isValidStringDateLaterThanMinDate = (value: string) => {
  if (isValidStringDate(value)) {
    const d = value.split('/')
    const dd = new Date(d[2] + '-' + d[1] + '-' + d[0])
    return dd.getTime() >= TRUVID_MIN_DATE.getTime()
  }
  return true
}

export { isValidDomainPattern, isValidStringDateTodayOrOlder, isValidStringDateLaterThanMinDate, atLeastNCheckbox, atLeastNOptions, isValidStringDate, isValidDate, notSameAs }
