<template>
  <div>
    <TeleportTooltip :placement="'top'" :hover="true" class="tooltip">
      <div class="count">+{{ props.extraTags.length }}</div>
      <template #content>
        <div class="selector-background" :class="{ wide: props.extraTags.length > 4 }">
          <div v-for="tag in props.extraTags" :key="tag.id" class="item">
            {{ $t('category_' + tag.id) }}
          </div>
        </div>
      </template>
    </TeleportTooltip>
  </div>
</template>

<script setup lang="ts">
import { TeleportTooltip } from '@/components/common'

const props = defineProps(['extraTags'])
</script>

<style scoped lang="scss">
.count {
  border-radius: 50%;
  width: 27px;
  height: 27px;
  padding: 10px;
  border: none;
  background-color: #7d81b2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 11px;
  line-height: 1;

  &.red {
    background: #fb2265;
  }

  &.blue {
    background: #2c95ff;
  }

  &.grey {
    background: #7d81b2;
  }
}

.selector {
  padding: 20px;
  background-color: #7d81b3;
}

.selector-background {
  cursor: default;
  max-width: min-content;
  white-space: nowrap;

  &.wide {
    width: 320px;
  }
}

.item {
  background: #f3f4fa;
  border: 1px solid #dadbe8;
  border-radius: 4px;
  margin: 4px;
  padding: 2px 20px;
  text-transform: capitalize;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  height: fit-content;
  /* body text */

  color: #4c4c66;
  opacity: 1;
  white-space: nowrap;

  img {
    margin-right: 8px;
  }
}
</style>
